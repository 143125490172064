import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, IconButton, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CurrencyTextField,
  Nil,
  NumberUtils,
  PuiSelect,
  PuiTextArea,
  PuiTextField,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { Eye as EyeIcon } from '@pbt/pbt-ui-components/src/icons'

import { WpCancelPaymentType } from '~/constants/paymentTypes'
// @ts-ignore
import { patientMembershipsSelectors } from '~/store/duck/patientMemberships'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getPatientName } from '~/store/reducers/patients'
import { DataHandle, Membership } from '~/types'

import { useWellnessPlanCancelReasons } from '../../wellness-plans/wellnessPlanUtils'

const useStyles = makeStyles(
  (theme) => ({
    section: {
      minHeight: 40,
      width: 280,
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tableBorder,
      },
    },
    currencyTextField: {
      width: 85,
    },
  }),
  { name: 'CancelMembership' },
)

export type CancelData = {
  chargeClient?: number
  creditBalance?: number
  notes: string
  otherReason: string
  reason: string
  refundClient?: number
  writeOff?: number
}

interface CancelMembershipProps {
  cancelData?: CancelData
  onViewTotalPaid: () => void
  onViewTotalUsed: () => void
  patientId: string | Nil
}

const CancelMembership = forwardRef<
  DataHandle<CancelData> | undefined,
  CancelMembershipProps
>(function CancelMembership(
  { patientId, cancelData, onViewTotalPaid, onViewTotalUsed },
  ref,
) {
  const classes = useStyles()

  const membership: Membership = useSelector(
    patientMembershipsSelectors.getItem(patientId),
  )
  const patientName = useSelector(getPatientName(patientId))
  const { t } = useTranslation(['Common', 'Tooltips', 'Dialogs'])

  const WellnessPlanCancelReasons: string[] = useWellnessPlanCancelReasons()

  const FieldsLabels = {
    REASON: t('Memberships:CANCEL_PLAN.REASON_INPUT', { patientName }),
    OTHER_REASON: t('Memberships:CANCEL_PLAN.OTHER_REASON_INPUT'),
  }

  const isRefund = membership.cancellationType === WpCancelPaymentType.REFUND
  const isCharge = membership.cancellationType === WpCancelPaymentType.CHARGE
  const isAllGood = !isRefund && !isCharge

  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )

  const cancellationAmount = membership.cancellationAmount || 0

  const {
    fields: {
      chargeClient,
      refundClient,
      writeOff,
      creditBalance,
      reason,
      otherReason,
      notes,
    },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'chargeClient',
        initialValue: cancelData?.chargeClient || cancellationAmount,
      },
      {
        name: 'refundClient',
        initialValue: cancelData?.refundClient || cancellationAmount,
      },
      { name: 'writeOff', initialValue: cancelData?.writeOff || 0 },
      { name: 'creditBalance', initialValue: cancelData?.creditBalance || 0 },
      { name: 'reason', initialValue: cancelData?.reason || '' },
      { name: 'otherReason', initialValue: cancelData?.otherReason || '' },
      { name: 'notes', initialValue: cancelData?.notes || '' },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [cancellationAmount, cancelData])

  useEffect(() => {
    const availableRefundWithCredit = cancellationAmount - creditBalance.value
    if (refundClient.value > availableRefundWithCredit) {
      refundClient.setValue(Utils.round(availableRefundWithCredit, 2))
    }
  }, [creditBalance.value])

  useEffect(() => {
    const availableCreditWithRefund = cancellationAmount - refundClient.value
    if (creditBalance.value > availableCreditWithRefund) {
      creditBalance.setValue(Utils.round(availableCreditWithRefund, 2))
    }
  }, [refundClient.value])

  useEffect(() => {
    writeOff.setValue(Utils.round(cancellationAmount - chargeClient.value, 2))
  }, [chargeClient.value])

  useEffect(() => {
    chargeClient.setValue(Utils.round(cancellationAmount - writeOff.value, 2))
  }, [writeOff.value])

  useImperativeHandle(ref, () => ({
    validate,
    get: () => ({
      otherReason: otherReason.value,
      reason: reason.value,
      chargeClient: chargeClient.value,
      refundClient: refundClient.value,
      creditBalance: creditBalance.value,
      writeOff: writeOff.value,
      notes: notes.value,
    }),
  }))

  const usageLabel = R.cond([
    [
      R.always(isRefund),
      R.always(t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.REFUND_TO_CLIENT')),
    ],
    [
      R.always(isCharge),
      R.always(t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.OWED_TO_PRACTICE')),
    ],
    [R.T, R.always(t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.ALL_GOOD'))],
  ])()

  return (
    <Grid container item direction="column" pb={1.5} pt={2} px={3}>
      <Text strong mb={1} variant="subheading3">
        {t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.PLAN_USAGE')}
      </Text>
      <Grid
        container
        item
        alignItems="center"
        className={classes.section}
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid container item alignItems="center" wrap="nowrap">
          <Text>{t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.TOTAL_USED')}</Text>
          <IconButton onClick={onViewTotalUsed}>
            <EyeIcon />
          </IconButton>
        </Grid>
        <Text>{NumberUtils.formatMoney(membership.totalUsedAmount)}</Text>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.section}
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid container item alignItems="center" wrap="nowrap">
          <Text>{t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.TOTAL_PAID')}</Text>
          <IconButton onClick={onViewTotalPaid}>
            <EyeIcon />
          </IconButton>
        </Grid>
        <Text>{NumberUtils.formatMoney(membership.totalPaidAmount)}</Text>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.section}
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Text>{t('Common:PAYMENTS.AMOUNT_REFUNDED')}</Text>
        <Text>
          {NumberUtils.formatMoney(membership?.totalRefundedAmount || 0)}
        </Text>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.section}
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Text strong variant="body2">
          {usageLabel}
        </Text>
        <Text strong variant="body2">
          {NumberUtils.formatMoney(cancellationAmount)}
        </Text>
      </Grid>
      {!isAllGood && (
        <>
          <Text strong mt={3} variant="subheading3">
            {t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.RECONCILE_DIFFERENCE')}
          </Text>
          <Grid
            container
            item
            alignItems="center"
            className={classes.section}
            justifyContent="space-between"
            wrap="nowrap"
          >
            {isCharge && (
              <>
                <TextWithTooltip
                  tooltipText={t('Tooltips:CANCEL_MEMBERSHIP_CHARGE_CLIENT')}
                  variant="body"
                >
                  {t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.CHARGE_CLIENT')}
                </TextWithTooltip>
                <CurrencyTextField
                  className={classes.currencyTextField}
                  field={chargeClient}
                  max={cancellationAmount}
                />
              </>
            )}
            {isRefund && (
              <>
                <Text>
                  {t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.REFUND_CLIENT')}
                </Text>
                <CurrencyTextField
                  className={classes.currencyTextField}
                  field={refundClient}
                  max={cancellationAmount}
                />
              </>
            )}
          </Grid>
          {(!isCurrentBusinessOmniChannel ||
            (isCurrentBusinessOmniChannel && isCharge)) && (
            <Grid
              container
              item
              alignItems="center"
              className={classes.section}
              justifyContent="space-between"
              wrap="nowrap"
            >
              {isCharge && (
                <>
                  <TextWithTooltip
                    tooltipText={t('Tooltips:CANCEL_MEMBERSHIP_WRITE_OFF')}
                    variant="body"
                  >
                    {t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.WRITE_OFF')}
                  </TextWithTooltip>
                  <CurrencyTextField
                    className={classes.currencyTextField}
                    field={writeOff}
                    max={cancellationAmount}
                  />
                </>
              )}
              {isRefund && (
                <>
                  <Text>
                    {t(
                      'Dialogs:CANCEL_MEMBERSHIP_DIALOG.CREDIT_CLIENT_BALANCE',
                    )}
                  </Text>
                  <CurrencyTextField
                    className={classes.currencyTextField}
                    field={creditBalance}
                    max={cancellationAmount}
                  />
                </>
              )}
            </Grid>
          )}
        </>
      )}
      <Text strong mt={3} variant="subheading3">
        {t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.CANCELLATION_NOTES')}
      </Text>
      <FormControl fullWidth margin="none">
        <InputLabel>{FieldsLabels.REASON}</InputLabel>
        <PuiSelect
          field={reason}
          items={WellnessPlanCancelReasons.map((cancelReason) => ({
            id: cancelReason,
            name: cancelReason,
          }))}
          renderEmpty={false}
        />
      </FormControl>
      {reason.value === 'Other' && (
        <PuiTextField
          field={otherReason}
          inputProps={{ maxLength: 1000 }}
          label={FieldsLabels.OTHER_REASON}
        />
      )}
      <PuiTextArea multiline field={notes} placeholder={t('Common:NOTES')} />
    </Grid>
  )
})

export default CancelMembership
