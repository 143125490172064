import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { BasePuiDialogProps, PuiDialog } from '@pbt/pbt-ui-components'

import {
  ChewyPaymentBody,
  ChewyPaymentBodyProps,
} from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyPaymentBody/ChewyPaymentBody'
import {
  ChewyPaymentFooter,
  ChewyPaymentFooterProps,
} from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyPaymentFooter/ChewyPaymentFooter'
import { ChewyPaymentStepper } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyPaymentsStepper/ChewyPaymentStepper'
import Typography from '~/components/elements/Typography/Typography'

const StyledPuiDialog = styled(PuiDialog)`
  .MuiPaper-root {
    width: 650px;
    max-width: 650px;
  }
`

export type ChewyPaymentDialogProps = BasePuiDialogProps &
  Pick<
    ChewyPaymentBodyProps,
    'clientName' | 'paymentMethods' | 'shippingAddresses'
  > &
  Pick<
    ChewyPaymentFooterProps,
    | 'accountBalance'
    | 'error'
    | 'isPaymentRequest'
    | 'onMoveToCart'
    | 'onPlaceOrder'
    | 'total'
  > & {
    activeStep?: number
  }

export const ChewyPaymentDialog = ({
  open,
  onClose,
  accountBalance,
  activeStep = 0,
  clientName,
  error,
  isPaymentRequest,
  onMoveToCart,
  onPlaceOrder,
  paymentMethods,
  shippingAddresses,
  total,
}: ChewyPaymentDialogProps) => {
  const { t } = useTranslation(['Common'])
  // TODO: M1 - toast on success

  const canPlaceChewyOrder =
    paymentMethods.length > 0 && shippingAddresses.length > 0

  return (
    <StyledPuiDialog
      actions={
        <ChewyPaymentFooter
          accountBalance={accountBalance}
          canPlaceChewyOrder={canPlaceChewyOrder}
          clientName={clientName}
          error={error}
          isPaymentRequest={isPaymentRequest}
          total={total}
          onMoveToCart={onMoveToCart}
          onPlaceOrder={onPlaceOrder}
        />
      }
      header={
        <>
          <Typography.H2>{t('Common:PAYMENTS.PAYMENT')}</Typography.H2>
          <Box ml={-1} pt={1}>
            <ChewyPaymentStepper activeStep={activeStep} />
          </Box>
        </>
      }
      open={open}
      onClose={onClose}
    >
      <ChewyPaymentBody
        clientName={clientName}
        p={2}
        paymentMethods={paymentMethods}
        shippingAddresses={shippingAddresses}
      />
    </StyledPuiDialog>
  )
}
