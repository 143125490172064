import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import { NumberStepper } from '~/components/elements/NumberStepper/NumberStepper'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { useIsDrug } from '~/store/hooks/orders'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Prescription, ShipmentItem, Variation } from '~/types'
import { getPrescriptionName } from '~/utils/prescription'
import useDialog from '~/utils/useDialog'

import { ShipmentItemSearchCriteria } from '../../soap/order/vaccine/ChooseShipmentItemDialog'

const DEFAULT_LINE_ITEM_QUANTITY = 1

// We need these fields to maintain backward compatibility with the old data structure
const getDispensedFields = (item: Prescription) => ({
  lotNumber: item?.lotNumber,
  serialNumber: item?.serialNumber,
  businessReferenceNumber: item?.businessReferenceNumber,
  manufacturerId: item?.manufacturerId,
  manufacturerName: item?.manufacturerName,
  expirationDate: item?.expirationDate,
})

const mapPrescriptionToDispensed = (item: Prescription): DispensedItem[] => [
  {
    lotNumber: item.lotNumber,
    serialNumber: item.serialNumber,
    lineItemQuantity: item.totalQuantity,
    inventoryShipmentItemId: '',
    id: '',
    expirationDate: item.expirationDate,
  },
]

const getIsPrescriptionHasFields = (fields: any) =>
  Object.values(fields).some(Boolean)

const useStyles = makeStyles(
  (theme) => ({
    tableHeading: {
      verticalAlign: 'top',
      padding: theme.spacing(1, 2),
    },
    tableViewport: {
      border: theme.constants.tableBorder,
    },
    tableCell: {
      padding: theme.spacing(0.5, 2),
      border: 'none',
    },
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
      '&:last-of-type': {
        borderTop: theme.constants.tableBorder,
        backgroundColor: theme.colors.tableBackground,
      },
    },
  }),
  { name: 'DispensedFromSectionNew' },
)

export interface DispensedFromSectionProps {
  onFieldsChange: (buttonVisible?: boolean) => void
  prescription: Prescription
}
type DispensedFields = ReturnType<typeof getDispensedFields>

type GetDispensedFromSection = DispensedFields & {
  lineItemQuantity?: number | string
}

export interface DispensedFromSectionHandleNew {
  chooseFromInventory: () => void
  get: () => DispensedItem[] | GetDispensedFromSection
}

export type DispensedItem = {
  expirationDate?: string
  id?: string
  inventoryShipmentItemId: string
  lineItemQuantity?: number | string
  lotNumber?: string
  serialNumber?: string
}

const DispensedFromSectionNew = forwardRef<
  DispensedFromSectionHandleNew,
  DispensedFromSectionProps
>(function DispensedFromSection({ onFieldsChange, prescription }, ref) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const [openShipmentItemsDialog] = useDialog(
    DialogNames.CHOOSE_SHIPMENT_ITEM_DIALOG,
  )

  const initialItems = prescription?.inventoryShipmentItemLogs?.length
    ? prescription.inventoryShipmentItemLogs
    : getIsPrescriptionHasFields(getDispensedFields(prescription))
    ? mapPrescriptionToDispensed(prescription)
    : []

  const [items, setItems] = useState<DispensedItem[]>(initialItems)

  useEffect(() => {
    onFieldsChange()
  }, [items])

  const variationId = prescription?.variation?.id

  const onInventoryChange = (props: ShipmentItem[] | ShipmentItem) => {
    if (!Array.isArray(props)) return
    const lineItems = props
      .filter((lineItem) => lineItem.id)
      .map((lineItem) => {
        const initialItem =
          items.find((item) => item.inventoryShipmentItemId === lineItem.id) ||
          ({} as DispensedItem)
        return {
          id: initialItem.id,
          inventoryShipmentItemId: lineItem?.id,
          lineItemQuantity:
            initialItem.lineItemQuantity || DEFAULT_LINE_ITEM_QUANTITY,
          lotNumber: lineItem?.lotNumber,
          serialNumber: lineItem?.serialNumber,
          expirationDate: lineItem?.expirationDate,
        }
      })
    setItems(lineItems)
  }
  const isDrug = useIsDrug(prescription)

  const handleChooseFromInventory = () => {
    openShipmentItemsDialog({
      order: {
        name: getPrescriptionName(prescription, isFoodCatalogEnabled) || '',
        variation: { id: variationId } as Variation,
      },
      showToggle: !isDrug,
      isMultiSelect: true,
      selectedItems: (items || []).map((item) => ({
        id: item?.inventoryShipmentItemId,
        amount: Number(item?.lineItemQuantity) || 0,
        lotNumber: item?.lotNumber,
        serialNumber: item?.serialNumber,
        expirationDate: item?.expirationDate,
      })),
      onSelect: onInventoryChange,
      searchCriteria: ShipmentItemSearchCriteria.INVENTORY,
    })
  }

  const handleAmountChange = (id: string, amount: number) => {
    if (!id) {
      setItems((prevItems) => {
        const updatedItems = [...prevItems]
        updatedItems[0].lineItemQuantity = amount
        return updatedItems
      })
    } else {
      setItems((prevItems) =>
        prevItems?.map((item) =>
          item.inventoryShipmentItemId === id
            ? { ...item, lineItemQuantity: amount }
            : item,
        ),
      )
    }
  }

  const handleDelete = (id: string) => {
    setItems((prevItems) =>
      prevItems.filter((item) => item.inventoryShipmentItemId !== id),
    )
  }

  useImperativeHandle(ref, () => ({
    get: () => {
      if (items.length === 1 && !items[0].inventoryShipmentItemId) {
        return {
          ...getDispensedFields(prescription),
          lineItemQuantity: items[0].lineItemQuantity,
        }
      }
      return items
    },
    chooseFromInventory: handleChooseFromInventory,
  }))

  if (!items?.length) {
    return null
  }

  const totalAmount = items?.reduce(
    (acc, item) => acc + (Number(item.lineItemQuantity) || 0),
    0,
  )

  return (
    <Table className={classes.tableViewport}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeading}>
            <Text strong variant="lowAccent2">
              {t('Common:LOT_SHIPMENT_NUMBER')}
            </Text>
          </TableCell>
          <TableCell className={classes.tableHeading}>
            <Text strong variant="lowAccent2">
              {t('Common:SERIAL_NUMBER_SIGN')}
            </Text>
          </TableCell>
          <TableCell />
          <TableCell align="right" className={classes.tableHeading}>
            <Text strong variant="lowAccent2">
              {t('Common:QUANTUTY_DISPENSED')}
            </Text>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((row) => (
          <TableRow className={classes.tableRow} key={row.lotNumber}>
            <TableCell className={classes.tableCell}>{row.lotNumber}</TableCell>
            <TableCell className={classes.tableCell}>
              {row.serialNumber}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <IconButton
                aria-label={t('Common:DELETE_ACTION')}
                onClick={() => handleDelete(row.inventoryShipmentItemId)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
            <TableCell
              className={classes.tableCell}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <NumberStepper
                value={Number(row.lineItemQuantity) || 0}
                onChange={(newValue: number | string) =>
                  handleAmountChange(
                    row.inventoryShipmentItemId,
                    Number(newValue),
                  )
                }
              />
            </TableCell>
          </TableRow>
        ))}
        <TableRow className={classes.tableRow}>
          <TableCell align="right" className={classes.tableCell} colSpan={4}>
            <Text strong m={0.5} variant="subheading3">
              {t('Common:TOTAL_DISPENSED')}: {totalAmount}
            </Text>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
})

export default DispensedFromSectionNew
