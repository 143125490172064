import {
  all,
  call,
  delay,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import {
  ApiError,
  Defaults,
  Nil,
  PermissionArea,
  UserPermissions,
} from '@pbt/pbt-ui-components'

import * as API from '~/api'
import ApiErrorTypes from '~/constants/apiErrorTypes'
import InvoiceType from '~/constants/InvoiceType'

import { fetchClient } from '../actions/clients'
import {
  addEstimateToSOAPOrEvent,
  addEstimateToSOAPOrEventFailure,
  addEstimateToSOAPOrEventSuccess,
  addEstimateToSOAPOrEventValidationFailure,
  addPatientEstimateSuccess,
  clearAppointmentInvoiceItems,
  cloneEstimate,
  cloneEstimateFailure,
  cloneEstimateSuccess,
  convertToLineItems,
  convertToLineItemsFailure,
  convertToLineItemsSuccess,
  deleteEstimate,
  deleteEstimateFailure,
  deleteEstimateSuccess,
  detachEstimateFromEvent,
  detachEstimateFromEventFailure,
  detachEstimateFromEventSuccess,
  editInvoiceLineItem,
  editInvoiceLineItemFailure,
  editInvoiceLineItemSuccess,
  fetchAppointmentInvoiceItems,
  fetchAppointmentInvoiceItemsFailure,
  fetchAppointmentInvoiceItemsSuccess,
  fetchBalanceHistory,
  fetchBalanceHistory as fetchBalanceHistoryAction,
  fetchBalanceHistoryFailure,
  fetchBalanceHistorySuccess,
  fetchBatchInvoice,
  fetchBatchInvoiceFailure,
  fetchBatchInvoiceSuccess,
  fetchCheckoutInvoices,
  fetchCheckoutInvoicesFailure,
  fetchCheckoutInvoicesSuccess,
  fetchEstimates,
  fetchEstimatesFailure,
  fetchEstimatesSuccess,
  fetchEstimateTemplate,
  fetchEstimateTemplateFailure,
  fetchEstimateTemplateSuccess,
  fetchInvoice,
  fetchInvoiceByEventId,
  fetchInvoiceByEventIdFailure,
  fetchInvoiceByEventIdSuccess,
  fetchInvoiceFailure,
  fetchInvoiceSuccess,
  fetchInvoiceTemplate,
  fetchInvoiceTemplateFailure,
  fetchInvoiceTemplateSuccess,
  fetchMoreItemsForBalanceHistory,
  fetchMoreItemsForBalanceHistoryFailure,
  fetchMoreItemsForBalanceHistorySuccess,
  fetchMorePatientEstimates,
  fetchMorePatientEstimatesFailure,
  fetchMorePatientEstimatesSuccess,
  fetchPatientEstimates,
  fetchPatientEstimatesFailure,
  fetchPatientEstimatesSuccess,
  fetchUnpaidInvoices,
  fetchUnpaidInvoicesFailure,
  fetchUnpaidInvoicesSuccess,
  printInvoice,
  printInvoiceFailure,
  printInvoiceSuccess,
  printInvoiceV2,
  rearrangeGroupedLineItems,
  rearrangeGroupedLineItemsFailure,
  rearrangeGroupedLineItemsSuccess,
  rearrangeInvoiceLineItems,
  rearrangeInvoiceLineItemsFailure,
  rearrangeInvoiceLineItemsSuccess,
  redeemLoyaltyPoints,
  redeemLoyaltyPointsFailure,
  redeemLoyaltyPointsSuccess,
  refreshBalanceHistory,
  refreshBalanceHistoryFailure,
  refreshBalanceHistorySuccess,
  saveEstimateToEvent,
  saveEstimateToEventFailure,
  saveEstimateToEventSuccess,
  saveEstimateToSOAP,
  saveEstimateToSOAPFailure,
  saveEstimateToSOAPSuccess,
  saveInvoice,
  saveInvoiceFailure,
  saveInvoiceSuccess,
  signEstimate,
  signEstimateFailure,
  signEstimateSuccess,
  updateBatchInvoice,
  updateInvoices,
  updateInvoiceTemplate,
} from '../actions/finance'
import { updatePatients } from '../actions/patients'
import {
  fetchSOAPOrderFilters,
  fetchSOAPOrders,
  updateInvoiceId,
  updateSOAP,
} from '../actions/soap'
import { fetchTimeline } from '../actions/timeline'
import { addEstimateToEvent, updateEvents } from '../actions/timetable'
import {
  ADD_ESTIMATE_TO_SOAP_OR_EVENT,
  CLONE_ESTIMATE,
  CONVERT_TO_LINE_ITEMS,
  DELETE_ESTIMATE,
  DETACH_ESTIMATE_FROM_EVENT,
  EDIT_INVOICE_LINE_ITEM,
  FETCH_APPOINTMENT_INVOICE_ITEMS,
  FETCH_BALANCE_HISTORY,
  FETCH_BATCH_INVOICE,
  FETCH_CHECKOUT_INVOICES,
  FETCH_ESTIMATE_TEMPLATE,
  FETCH_ESTIMATES,
  FETCH_INVOICE,
  FETCH_INVOICE_BY_EVENT_ID,
  FETCH_INVOICE_TEMPLATE,
  FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY,
  FETCH_MORE_PATIENT_ESTIMATES,
  FETCH_PATIENT_ESTIMATES,
  FETCH_UNPAID_INVOICES,
  PRINT_INVOICE,
  PRINT_INVOICE_V2,
  REARRANGE_GROUPED_LINE_ITEMS,
  REARRANGE_INVOICE_LINE_ITEMS,
  REDEEM_LOYALTY_POINTS,
  REFRESH_BALANCE_HISTORY,
  SAVE_ESTIMATE_TO_EVENT,
  SAVE_ESTIMATE_TO_SOAP,
  SAVE_INVOICE,
  SIGN_ESTIMATE,
} from '../actions/types/finance'
import { updateUsers } from '../actions/users'
import { registerWarnAlert } from '../duck/uiAlerts'
import { getCRUDByArea } from '../reducers/auth'
import {
  getAppointmentId,
  getClientId,
  getSoapBusinessId,
  getSoapId,
} from '../reducers/soap'
import { processSOAPData } from './soap'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

function* fetchCurrentSoapClient() {
  const clientId: string = yield select(getClientId)
  if (clientId) {
    yield put(fetchClient({ clientId }))
  }
}

export function* fetchBalanceHistorySaga({
  clientId,
  from,
  to,
}: ReturnType<typeof fetchBalanceHistory>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchBalanceHistory, clientId, from, to)
    yield call(updateEntities, entities)
    yield put(fetchBalanceHistorySuccess(list, totalCount))
  } catch (error) {
    yield put(fetchBalanceHistoryFailure(error as ApiError))
  }
}

export function* refreshBalanceHistorySaga({
  clientId,
  to,
}: ReturnType<typeof refreshBalanceHistory>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchBalanceHistory, clientId, 0, to)
    yield call(updateEntities, entities)
    yield put(refreshBalanceHistorySuccess(list, totalCount))
  } catch (error) {
    yield put(refreshBalanceHistoryFailure(error as ApiError))
  }
}

export function* fetchMoreItemsForBalanceHistorySaga({
  clientId,
  from,
  to,
}: ReturnType<typeof fetchMoreItemsForBalanceHistory>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchBalanceHistory, clientId, from, to)
    yield call(updateEntities, entities)
    yield put(fetchMoreItemsForBalanceHistorySuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForBalanceHistoryFailure(error as ApiError))
  }
}

export function* fetchInvoiceSaga({
  invoiceId,
}: ReturnType<typeof fetchInvoice>) {
  try {
    const { result, entities } = yield* requestAPI(API.fetchInvoice, invoiceId)
    yield call(updateEntities, entities)
    yield put(fetchInvoiceSuccess(result))
  } catch (error) {
    yield put(fetchInvoiceFailure(error as ApiError))
  }
}

export function* fetchUnpaidInvoicesSaga({
  clientId,
}: ReturnType<typeof fetchUnpaidInvoices>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchUnpaidInvoices,
      clientId,
    )
    yield call(updateEntities, entities)
    yield put(fetchUnpaidInvoicesSuccess(result))
  } catch (error) {
    yield put(fetchUnpaidInvoicesFailure(error as ApiError))
  }
}

export function* fetchBatchInvoiceSaga({
  invoiceIds,
}: ReturnType<typeof fetchBatchInvoice>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchBatchInvoice,
      invoiceIds,
    )
    yield call(updateEntities, entities)
    const batchInvoice =
      invoiceIds.length > 1 ? result : entities.invoices[result]
    yield put(fetchBatchInvoiceSuccess(batchInvoice))
  } catch (error) {
    yield put(fetchBatchInvoiceFailure(error as ApiError))
  }
}

export function* fetchInvoiceByEventIdSaga({
  eventId,
  clientId,
}: ReturnType<typeof fetchInvoiceByEventId>) {
  try {
    const {
      result,
      entities: { users, patients, invoices },
    } = yield* requestAPI(API.fetchInvoiceByEventId, eventId, clientId)
    yield put(updateUsers(users))
    yield put(updatePatients(patients))
    if (result) {
      yield put(updateInvoices(invoices))
      if (invoices[result].type === InvoiceType.INVOICE) {
        yield put(updateInvoiceId(result))
      }
    } else {
      yield put(updateInvoiceTemplate(invoices[result]))
    }
    yield put(fetchInvoiceByEventIdSuccess(result))
  } catch (error) {
    yield put(fetchInvoiceByEventIdFailure(error as ApiError))
  }
}

export function* fetchInvoiceTemplateSaga({
  clientId,
  patientId,
  eventId,
}: ReturnType<typeof fetchInvoiceTemplate>) {
  try {
    const {
      result,
      entities: { users, patients, invoices },
    } = yield* requestAPI(
      API.fetchInvoiceTemplateV2,
      clientId,
      patientId,
      eventId,
    )
    yield put(updateUsers(users))
    yield put(updatePatients(patients))
    yield put(fetchInvoiceTemplateSuccess(invoices[result]))
  } catch (error) {
    yield put(fetchInvoiceTemplateFailure(error as ApiError))
  }
}

export function* fetchEstimateTemplateSaga({
  clientId,
  patientId,
}: ReturnType<typeof fetchEstimateTemplate>) {
  try {
    const {
      result,
      entities: { users, patients, invoices },
    } = yield* requestAPI(API.fetchEstimateTemplate, clientId, patientId)
    yield put(updateUsers(users))
    yield put(updatePatients(patients))
    yield put(fetchEstimateTemplateSuccess(invoices[result]))
  } catch (error) {
    yield put(fetchEstimateTemplateFailure(error as ApiError))
  }
}

export function* saveInvoiceSaga({
  invoice,
  isNew,
}: ReturnType<typeof saveInvoice>) {
  try {
    const {
      result,
      entities: { users, patients, invoices },
    } = yield* requestAPI(API.saveInvoiceV2, invoice)
    const { [result]: mainInvoice, ...otherInvoices } = invoices

    const paymentPermissions: UserPermissions = yield select(
      getCRUDByArea(PermissionArea.PAYMENT),
    )

    yield put(updateUsers(users))
    yield put(updatePatients(patients))

    // we tried saving batch invoice which doesn't really save it
    // but instead saves each individual invoice inside batch
    // so we don't need to put null key in our invoices map
    if (!mainInvoice.id && mainInvoice.invoices?.length > 0) {
      yield put(updateBatchInvoice(mainInvoice))
      yield put(updateInvoices(otherInvoices))
    } else {
      yield put(updateInvoices({ ...otherInvoices, [result]: mainInvoice }))
    }
    if (isNew && invoices[result].type === InvoiceType.INVOICE) {
      yield put(updateInvoiceId(result))
    }
    if (isNew && paymentPermissions.read && invoice.clientId) {
      yield put(fetchBalanceHistoryAction(invoice.clientId))
    }
    yield put(saveInvoiceSuccess(invoices[result]))
    if (isNew && invoice?.type === InvoiceType.ESTIMATE) {
      yield put(addPatientEstimateSuccess(result))
    }
    const soapId: string = yield select(getSoapId)
    const soapBusinessId: string | Nil = yield select(getSoapBusinessId)
    const appointmentId: string = yield select(getAppointmentId)
    if (soapId && appointmentId) {
      yield put(fetchSOAPOrders(soapId, soapBusinessId))
      yield put(fetchSOAPOrderFilters(appointmentId, soapBusinessId))
      yield put(clearAppointmentInvoiceItems())
      yield fetchCurrentSoapClient()
    }
  } catch (e) {
    const error = e as ApiError
    const errorBody = error?.responseBody
    const errorType = errorBody?.type

    if (Object.values(ApiErrorTypes).indexOf(errorType) !== -1) {
      const errorBodyMessage = errorBody?.message
      const errorMessage =
        error.status === 409 && errorBody?.item?.name
          ? `${errorBodyMessage.replace('item', errorBody.item.name)}`
          : errorBodyMessage
      yield put(registerWarnAlert(errorMessage))
    }
    yield put(saveInvoiceFailure(error))
  }
}

export function* printInvoiceSaga({
  invoiceIds,
  params,
}: ReturnType<typeof printInvoice>) {
  try {
    const result = yield* requestAPI(API.printInvoice, invoiceIds, params)
    yield put(printInvoiceSuccess(result))
  } catch (error) {
    yield put(printInvoiceFailure(error as ApiError))
  }
}

export function* printInvoiceV2Saga({
  invoiceId,
  expandedGroups,
  includeServiceFee,
}: ReturnType<typeof printInvoiceV2>) {
  try {
    const { content } = yield* requestAPI(API.printInvoiceV2, {
      invoiceId,
      expandedGroups,
      includeServiceFee,
    })
    yield put(printInvoiceSuccess(content))
  } catch (error) {
    yield put(printInvoiceFailure(error as ApiError))
  }
}

export function* convertToLineItemsSaga({
  invoiceId,
  clientId,
  patientId,
  invoiceType,
  otcItems,
}: ReturnType<typeof convertToLineItems>) {
  try {
    const result = yield* requestAPI(
      API.convertToLineItems,
      invoiceId,
      clientId,
      patientId,
      invoiceType,
      otcItems,
    )
    yield put(convertToLineItemsSuccess(result))
  } catch (error) {
    yield put(convertToLineItemsFailure(error as ApiError))
  }
}

export function* deleteEstimateSaga({
  estimateId,
}: ReturnType<typeof deleteEstimate>) {
  try {
    yield* requestAPI(API.deleteEstimate, estimateId)
    yield put(deleteEstimateSuccess(estimateId))
  } catch (error) {
    yield put(deleteEstimateFailure(error as ApiError))
  }
}

export function* fetchEstimatesSaga({
  patientId,
}: ReturnType<typeof fetchEstimates>) {
  try {
    const {
      result,
      entities: { invoices, users, patients },
    } = yield* requestAPI(API.fetchEstimates, patientId)
    yield put(updateUsers(users))
    yield put(updatePatients(patients))
    yield put(updateInvoices(invoices))
    yield put(fetchEstimatesSuccess(result))
  } catch (error) {
    yield put(fetchEstimatesFailure(error as ApiError))
  }
}

export function* addEstimateToSOAPorEventSaga({
  allowNewSoap,
  estimateId,
  soapId,
  eventId,
  copyItems,
  cloningOptions,
}: ReturnType<typeof addEstimateToSOAPOrEvent>) {
  try {
    const {
      result: { soap, estimate: copiedEstimateId },
      entities,
    } = yield* requestAPI(API.addEstimateToSOAPOrEvent, {
      estimateId,
      soapId,
      eventId,
      copyItems,
      allowClone: cloningOptions?.allowClone,
      checkInactiveLineItems: cloningOptions?.checkInactiveLineItems,
      allowNewSoap,
    })
    yield call(updateEntities, entities)
    if (soap) {
      yield processSOAPData(soap)
      yield put(updateSOAP(soap))
    }
    yield put(
      addEstimateToEvent(estimateId, eventId || soap.appointment.appointmentId),
    )
    if (cloningOptions?.allowClone && cloningOptions?.checkInactiveLineItems) {
      yield put(addPatientEstimateSuccess(copiedEstimateId))
      if (cloningOptions?.refetchTimelineAfterCloning) {
        yield put(fetchTimeline())
      }
    }
    yield put(addEstimateToSOAPOrEventSuccess())
  } catch (error) {
    if (cloningOptions) {
      yield put(addEstimateToSOAPOrEventValidationFailure(error as ApiError))
    } else {
      yield put(addEstimateToSOAPOrEventFailure(error as ApiError))
    }
  }
}

export function* saveEstimateToSOAPSaga({
  estimate,
  soapId,
}: ReturnType<typeof saveEstimateToSOAP>) {
  try {
    const {
      result: { estimate: estimateId, soap },
      entities,
    } = yield* requestAPI(API.saveEstimateToSOAP, estimate, soapId)
    yield call(updateEntities, entities)
    yield processSOAPData(soap)
    yield put(updateSOAP(soap))
    const { appointmentId } = soap.appointment
    yield put(addEstimateToEvent(estimateId, appointmentId))
    yield put(saveEstimateToSOAPSuccess(entities.invoices[estimateId]))
  } catch (error) {
    yield put(saveEstimateToSOAPFailure(error as ApiError))
  }
}

export function* saveEstimateToEventSaga({
  allowNewSoap,
  estimate,
  eventId,
}: ReturnType<typeof saveEstimateToEvent>) {
  try {
    const {
      result: { estimate: estimateId },
      entities,
    } = yield* requestAPI(API.saveEstimateToEvent, {
      estimate,
      eventId,
      allowNewSoap,
    })
    yield call(updateEntities, entities)
    yield put(saveEstimateToEventSuccess(entities.invoices[estimateId]))
  } catch (error) {
    yield put(saveEstimateToEventFailure(error as ApiError))
  }
}

export function* detachEstimateFromEventSaga({
  estimateId,
  eventId,
}: ReturnType<typeof detachEstimateFromEvent>) {
  try {
    const {
      entities: { users, patients, invoices, events },
    } = yield* requestAPI(API.detachEstimateFromEvent, estimateId, eventId)
    yield put(updateUsers(users))
    yield put(updatePatients(patients))
    yield put(updateInvoices(invoices))
    yield put(updateEvents(events))
    yield put(detachEstimateFromEventSuccess())
  } catch (error) {
    yield put(detachEstimateFromEventFailure(error as ApiError))
  }
}

export function* signEstimateSaga({
  estimateId,
  signature,
  signerId,
  signerName,
  options = {},
}: ReturnType<typeof signEstimate>) {
  try {
    const {
      entities: { invoices },
    } = yield* requestAPI(
      API.signEstimate,
      estimateId,
      signature,
      signerId,
      signerName,
    )
    yield put(updateInvoices(invoices))
    if (options.fromTimeline) {
      yield put(fetchTimeline())
    }
    yield put(signEstimateSuccess())
  } catch (error) {
    yield put(signEstimateFailure(error as ApiError))
  }
}

export function* fetchCheckoutInvoicesSaga({
  eventId,
}: ReturnType<typeof fetchCheckoutInvoices>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchCheckoutInvoices,
      eventId,
    )
    yield call(updateEntities, entities)
    yield put(fetchCheckoutInvoicesSuccess(result))
  } catch (error) {
    yield put(fetchCheckoutInvoicesFailure(error as ApiError))
  }
}

export function* redeemLoyaltyPointsSaga({
  amount,
  invoiceId,
}: ReturnType<typeof redeemLoyaltyPoints>) {
  try {
    yield* requestAPI(API.redeemLoyaltyPoints, amount, invoiceId)
    yield put(redeemLoyaltyPointsSuccess())
  } catch (error) {
    yield put(redeemLoyaltyPointsFailure(error as ApiError))
  }
}

export function* fetchAppointmentInvoiceItemsSaga({
  eventId,
}: ReturnType<typeof fetchAppointmentInvoiceItems>) {
  try {
    const response = yield* requestAPI(
      API.fetchAppointmentInvoiceItems,
      eventId,
    )
    yield put(fetchAppointmentInvoiceItemsSuccess(response || []))
  } catch (error) {
    yield put(fetchAppointmentInvoiceItemsFailure(error as ApiError))
  }
}

export function* editInvoiceLineItemSaga({
  id,
  input,
  invoiceId,
}: ReturnType<typeof editInvoiceLineItem>) {
  try {
    yield delay(Defaults.DEBOUNCE_ACTION_TIME)
    const response = yield* requestAPI(API.updateInvoiceLineItem, id, input)
    yield put(editInvoiceLineItemSuccess(id, invoiceId, response))
  } catch (error) {
    yield put(editInvoiceLineItemFailure(error as ApiError))
  }
}

export function* rearrangeInvoiceLineItemsSaga({
  invoiceId,
  newArrangement,
}: ReturnType<typeof rearrangeInvoiceLineItems>) {
  try {
    yield delay(Defaults.DEBOUNCE_ACTION_TIME)
    const response = yield* requestAPI(
      API.rearrangeInvoiceLineItems,
      invoiceId,
      newArrangement,
    )
    yield put(rearrangeInvoiceLineItemsSuccess(invoiceId, response))
  } catch (error) {
    yield put(rearrangeInvoiceLineItemsFailure(error as ApiError))
  }
}

export function* rrearrangeGroupedLineItemsSaga({
  invoiceId,
  input,
}: ReturnType<typeof rearrangeGroupedLineItems>) {
  try {
    yield delay(Defaults.DEBOUNCE_ACTION_TIME)
    const response = yield* requestAPI(API.rearrangeGroupedLineItems, {
      invoiceId,
      input,
    })
    yield put(rearrangeGroupedLineItemsSuccess(invoiceId, response))
  } catch (error) {
    yield put(rearrangeGroupedLineItemsFailure(error as ApiError))
  }
}

export function* fetchPatientEstimatesSaga({
  patientId,
  soapId,
  offset,
  limit,
}: ReturnType<typeof fetchPatientEstimates>) {
  try {
    const {
      result: { data: patientEstimatesIdsList, totalCount },
      entities,
    } = yield* requestAPI(
      API.fetchPatientEstimates,
      patientId,
      soapId,
      offset,
      limit,
    )
    yield call(updateEntities, entities)
    yield put(fetchPatientEstimatesSuccess(patientEstimatesIdsList, totalCount))
  } catch (error) {
    yield put(fetchPatientEstimatesFailure(error as ApiError))
  }
}

export function* fetchMorePatientEstimatesSaga({
  patientId,
  soapId,
  offset,
  limit,
}: ReturnType<typeof fetchMorePatientEstimates>) {
  try {
    const {
      result: { data: patientEstimatesIdsList, totalCount },
      entities,
    } = yield* requestAPI(
      API.fetchPatientEstimates,
      patientId,
      soapId,
      offset,
      limit,
    )
    yield call(updateEntities, entities)
    yield put(
      fetchMorePatientEstimatesSuccess(patientEstimatesIdsList, totalCount),
    )
  } catch (error) {
    yield put(fetchMorePatientEstimatesFailure(error as ApiError))
  }
}

export function* cloneEstimateSaga({
  estimateId,
  checkInactiveLineItems,
}: ReturnType<typeof cloneEstimate>) {
  try {
    const { result: clonedEstimateId, entities } = yield* requestAPI(
      API.cloneEstimate,
      estimateId,
      checkInactiveLineItems,
    )
    yield call(updateEntities, entities)
    yield put(cloneEstimateSuccess(clonedEstimateId))
  } catch (error) {
    yield put(cloneEstimateFailure(error as ApiError))
  }
}

function* watchCloneEstimateEstimate() {
  yield takeLeading(CLONE_ESTIMATE, cloneEstimateSaga)
}

function* watchFetchPatientEstimate() {
  yield takeLeading(FETCH_PATIENT_ESTIMATES, fetchPatientEstimatesSaga)
}

function* watchMoreFetchPatientEstimate() {
  yield takeLeading(FETCH_MORE_PATIENT_ESTIMATES, fetchMorePatientEstimatesSaga)
}

function* watchFetchBalanceHistory() {
  yield takeLeading(FETCH_BALANCE_HISTORY, fetchBalanceHistorySaga)
}

function* watchRefreshBalanceHistory() {
  yield takeLeading(REFRESH_BALANCE_HISTORY, refreshBalanceHistorySaga)
}

function* watchFetchMoreItemsForBalanceHistory() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY,
    fetchMoreItemsForBalanceHistorySaga,
  )
}

function* watchFetchInvoice() {
  yield takeLeading(FETCH_INVOICE, fetchInvoiceSaga)
}

function* watchFetchInvoiceByEventId() {
  yield takeLeading(FETCH_INVOICE_BY_EVENT_ID, fetchInvoiceByEventIdSaga)
}

function* watchFetchInvoiceTemplate() {
  yield takeLeading(FETCH_INVOICE_TEMPLATE, fetchInvoiceTemplateSaga)
}

function* watchFetchEstimateTemplate() {
  yield takeLeading(FETCH_ESTIMATE_TEMPLATE, fetchEstimateTemplateSaga)
}

function* watchSaveInvoice() {
  yield takeLeading(SAVE_INVOICE, saveInvoiceSaga)
}

function* watchPrintInvoice() {
  yield takeLeading(PRINT_INVOICE, printInvoiceSaga)
}

function* watchPrintInvoiceV2() {
  yield takeLeading(PRINT_INVOICE_V2, printInvoiceV2Saga)
}

function* watchConvertToLineItems() {
  yield takeLeading(CONVERT_TO_LINE_ITEMS, convertToLineItemsSaga)
}

function* watchDeleteEstimate() {
  yield takeLeading(DELETE_ESTIMATE, deleteEstimateSaga)
}

function* watchFetchEstimates() {
  yield takeLatest(FETCH_ESTIMATES, fetchEstimatesSaga)
}

function* watchAddEstimateToSoapOrEvent() {
  yield takeLatest(ADD_ESTIMATE_TO_SOAP_OR_EVENT, addEstimateToSOAPorEventSaga)
}

function* watchSaveEstimateToSoap() {
  yield takeLatest(SAVE_ESTIMATE_TO_SOAP, saveEstimateToSOAPSaga)
}

function* watchSaveEstimateToEvent() {
  yield takeLatest(SAVE_ESTIMATE_TO_EVENT, saveEstimateToEventSaga)
}

function* watchDetachEstimateFromEvent() {
  yield takeLatest(DETACH_ESTIMATE_FROM_EVENT, detachEstimateFromEventSaga)
}

function* watchSignEstimate() {
  yield takeLatest(SIGN_ESTIMATE, signEstimateSaga)
}

function* watchFetchCheckoutInvoices() {
  yield takeLatest(FETCH_CHECKOUT_INVOICES, fetchCheckoutInvoicesSaga)
}

function* watchFetchUnpaidInvoices() {
  yield takeLatest(FETCH_UNPAID_INVOICES, fetchUnpaidInvoicesSaga)
}

function* watchFetchBatchInvoice() {
  yield takeLatest(FETCH_BATCH_INVOICE, fetchBatchInvoiceSaga)
}

function* watchRedeemLoyaltyPoints() {
  yield takeLatest(REDEEM_LOYALTY_POINTS, redeemLoyaltyPointsSaga)
}

function* watchFetchAppointmentInvoiceItems() {
  yield takeLatest(
    FETCH_APPOINTMENT_INVOICE_ITEMS,
    fetchAppointmentInvoiceItemsSaga,
  )
}

function* watchEditInvoiceLineItemSaga() {
  yield takeLatest(EDIT_INVOICE_LINE_ITEM, editInvoiceLineItemSaga)
}

function* watchRearrangeInvoiceLineItemsSaga() {
  yield takeLatest(REARRANGE_INVOICE_LINE_ITEMS, rearrangeInvoiceLineItemsSaga)
}

function* watchRearrangeGroupedLineItemsSaga() {
  yield takeLatest(REARRANGE_GROUPED_LINE_ITEMS, rrearrangeGroupedLineItemsSaga)
}

export default function* financeSaga() {
  yield all([
    watchCloneEstimateEstimate(),
    watchMoreFetchPatientEstimate(),
    watchFetchPatientEstimate(),
    watchFetchBalanceHistory(),
    watchRefreshBalanceHistory(),
    watchFetchMoreItemsForBalanceHistory(),
    watchFetchBalanceHistory,
    watchRefreshBalanceHistory,
    watchFetchMoreItemsForBalanceHistory,
    watchFetchInvoice(),
    watchFetchInvoiceByEventId(),
    watchFetchInvoiceTemplate(),
    watchFetchEstimateTemplate(),
    watchSaveInvoice(),
    watchPrintInvoice(),
    watchPrintInvoiceV2(),
    watchConvertToLineItems(),
    watchDeleteEstimate(),
    watchFetchEstimates(),
    watchAddEstimateToSoapOrEvent(),
    watchSaveEstimateToSoap(),
    watchSaveEstimateToEvent(),
    watchDetachEstimateFromEvent(),
    watchSignEstimate(),
    watchFetchCheckoutInvoices(),
    watchFetchUnpaidInvoices(),
    watchFetchBatchInvoice(),
    watchRedeemLoyaltyPoints(),
    watchFetchAppointmentInvoiceItems(),
    watchEditInvoiceLineItemSaga(),
    watchRearrangeInvoiceLineItemsSaga(),
    watchRearrangeGroupedLineItemsSaga(),
  ])
}
