import React from 'react'
import { useTranslation } from 'react-i18next'

import { Stepper, StepperProps } from '~/components/elements/Stepper/Stepper'

type ChewyPaymentStepperProps = Pick<StepperProps, 'activeStep'>

export const ChewyPaymentStepper = (props: ChewyPaymentStepperProps) => {
  const { t } = useTranslation(['Dialogs'])

  const steps: StepperProps['steps'] = [
    { label: t('Dialogs:CHEWY_PAYMENT_DIALOG.STEP_CHEWY_ORDER') },
    { label: t('Dialogs:CHEWY_PAYMENT_DIALOG.STEP_CVC') },
  ]

  return (
    <Stepper alwaysShowNumbers steps={steps} sx={{ width: 336 }} {...props} />
  )
}
