import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  DateUtils,
  moment,
  Nil,
  NumberUtils,
  PuiDialog,
  Text,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import {
  PAYMENT_LINK_PLACEHOLDER,
  RequestPaymentType,
  RequestPaymentWorkflow,
  UI_PAYMENT_LINK_PLACEHOLDERS,
} from '~/constants/paymentTypes'
import { fetchClient } from '~/store/actions/clients'
import { emailPayment } from '~/store/actions/communications'
import { useCreatedConversationsInfo } from '~/store/hooks/conversations'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getFinanceIsLoading } from '~/store/reducers/finance'
import { getPatientsList } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import {
  BatchInvoice,
  EmailEntityConfigRecipient,
  Invoice,
  InvoiceOrEstimate,
} from '~/types'
import { composePatientNames } from '~/utils/paymentUtils'
import {
  normalizeTextForHtml,
  replaceWithTargetPlaceholder,
} from '~/utils/requestPaymentUtils'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'

import ConversationMessageFormattingArea, {
  ConversationMessageFormattingAreaHandle,
} from '../../communications/ConversationMessageFormattingArea'
import ConversationTransportSelect from '../../communications/new-conversation-dialog/ConversationTransportSelect'
import { getIsBatchInvoice } from '../invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 1024,
      maxWidth: 1024,
    },
    radioLabel: {
      fontSize: '1.4rem',
      marginLeft: 0,
      paddingLeft: 0,
    },
    labelRoot: {
      color: theme.colors.secondaryText,
      margin: 0,
    },
    radioContainer: {
      marginBottom: theme.spacing(2),
    },
    radio: {
      padding: '6px',
      marginLeft: theme.spacing(-1),
    },
    buttonsSection: {
      borderTop: theme.constants.tableBorder,
    },
    sendButton: {
      width: 120,
    },
    messageFormattingArea: {
      marginTop: theme.spacing(3),
    },
    toContainer: {
      width: '50%',
    },
  }),
  { name: 'PaymentRequestDialog' },
)

const mapClientToClientChip = (client: User) => ({
  id: client.id,
  name: Utils.getPersonString(client),
})

const ALLOWED_TRANSPORTS = [
  ConversationTransport.Email,
  ConversationTransport.Sms,
  ConversationTransport.Boop,
]

export interface PaymentRequestDialogProps extends BasePuiDialogProps {
  balancePayment?: boolean
  clientId?: string
  invoice?: InvoiceOrEstimate | BatchInvoice | Nil
}

const PaymentRequestDialog = ({
  invoice,
  clientId: clientIdProp,
  onClose,
  balancePayment = false,
  ...rest
}: PaymentRequestDialogProps) => {
  const dispatch = useDispatch()
  const clientId = invoice?.clientId || clientIdProp
  const patientId = invoice?.patient

  const patientNames = composePatientNames(
    useSelector(getPatientsList(invoice?.patients)).map((it) => it.name),
  )

  const classes = useStyles()
  const rootRef = useRef<HTMLDivElement>(null)
  const messageFormattingAreaRef =
    useRef<ConversationMessageFormattingAreaHandle>(null)
  const currentBusiness = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId))
  const isLoading = useSelector(getFinanceIsLoading)
  const { t } = useTranslation(['Common', 'Dialogs'])

  const [transport, setTransport] = useState<ConversationTransport>()
  const [paymentType, setPaymentType] = useState(RequestPaymentType.PAYMENT)
  const [selectedRecipients, setSelectedRecipients] = useState(
    client ? [mapClientToClientChip(client)] : [],
  )

  const nowDate = DateUtils.formatDate(moment().toISOString())
  const balance = NumberUtils.formatMoney(client?.balance)

  const boopSelect = transport === ConversationTransport.Boop
  const phoneSelect = transport === ConversationTransport.Sms
  const workflow =
    paymentType === RequestPaymentType.PAYMENT
      ? balancePayment
        ? RequestPaymentWorkflow.BALANCE_PAYMENT
        : RequestPaymentWorkflow.INVOICE_PAYMENT
      : balancePayment
      ? RequestPaymentWorkflow.BALANCE_AUTHORIZATION
      : RequestPaymentWorkflow.INVOICE_AUTHORIZATION

  const workflowMessages = {
    [RequestPaymentWorkflow.INVOICE_PAYMENT]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.WORKFLOW_MESSAGES.INVOICE_PAYMENT',
      {
        placeholder:
          UI_PAYMENT_LINK_PLACEHOLDERS[RequestPaymentWorkflow.INVOICE_PAYMENT],
      },
    ),
    [RequestPaymentWorkflow.INVOICE_AUTHORIZATION]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.WORKFLOW_MESSAGES.INVOICE_AUTHORIZATION',
      {
        placeholder:
          UI_PAYMENT_LINK_PLACEHOLDERS[
            RequestPaymentWorkflow.INVOICE_AUTHORIZATION
          ],
      },
    ),
    [RequestPaymentWorkflow.BALANCE_PAYMENT]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.WORKFLOW_MESSAGES.BALANCE_PAYMENT',
      {
        balance,
        currentBusinessName: currentBusiness?.name,
        placeholder:
          UI_PAYMENT_LINK_PLACEHOLDERS[RequestPaymentWorkflow.BALANCE_PAYMENT],
      },
    ),
    [RequestPaymentWorkflow.BALANCE_AUTHORIZATION]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.WORKFLOW_MESSAGES.BALANCE_AUTHORIZATION',
      {
        balance,
        currentBusinessName: currentBusiness?.name,
        placeholder:
          UI_PAYMENT_LINK_PLACEHOLDERS[
            RequestPaymentWorkflow.BALANCE_AUTHORIZATION
          ],
      },
    ),
  }

  const workflowSubjects = {
    [RequestPaymentWorkflow.INVOICE_PAYMENT]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.WORKFLOW_SUBJECTS.INVOICE_PAYMENT',
      {
        patientNames,
      },
    ),
    [RequestPaymentWorkflow.INVOICE_AUTHORIZATION]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.WORKFLOW_SUBJECTS.INVOICE_AUTHORIZATION',
      {
        patientNames,
      },
    ),
    [RequestPaymentWorkflow.BALANCE_PAYMENT]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.WORKFLOW_SUBJECTS.BALANCE_PAYMENT',
      {
        nowDate,
      },
    ),
    [RequestPaymentWorkflow.BALANCE_AUTHORIZATION]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.WORKFLOW_SUBJECTS.BALANCE_AUTHORIZATION',
      { nowDate },
    ),
  }

  const messageBody = t('Dialogs:PAYMENT_REQUEST_DIALOG.MESSAGE_BODY', {
    clientFirstName: client?.firstName,
    currentBusinessName: currentBusiness?.name,
    workflowMessage: workflowMessages[workflow],
  })
  const normalizedMessageBody =
    phoneSelect || boopSelect ? messageBody : normalizeTextForHtml(messageBody)
  const initialSubject = workflowSubjects[workflow]
  const isBatchInvoice = getIsBatchInvoice(invoice)

  const {
    fields: { subject, message, to },
    validate,
  } = useNewConversationValidationForm(
    { transport, client },
    { initialSubject, initialMessage: normalizedMessageBody },
  )

  const workflowSuccessMessages = {
    [RequestPaymentWorkflow.INVOICE_PAYMENT]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.REQUEST_TO_PAY_INVOICE',
    ),
    [RequestPaymentWorkflow.INVOICE_AUTHORIZATION]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.REQUEST_TO_AUTHORIZE_PAYMENT',
    ),
    [RequestPaymentWorkflow.BALANCE_PAYMENT]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.REQUEST_TO_PAY_BALANCE',
    ),
    [RequestPaymentWorkflow.BALANCE_AUTHORIZATION]: t(
      'Dialogs:PAYMENT_REQUEST_DIALOG.REQUEST_TO_AUTHORIZE_PAYMENT',
    ),
  }

  const onConversationCreationSuccess = () => {
    if (onClose) {
      onClose()
    }
  }

  const displayConversationCreationResult = useCreatedConversationsInfo({
    getIsConversationCreating: getFinanceIsLoading,
    onConversationCreationSuccess,
    createdInfoDialogProps: {
      titleMessageName: workflowSuccessMessages[workflow],
    },
  })

  const radioClasses = {
    root: classes.labelRoot,
    label: classes.radioLabel,
  }

  const handleSend = () => {
    if (validate()) {
      dispatch(
        emailPayment({
          clientId,
          invoiceIds: balancePayment
            ? undefined
            : isBatchInvoice
            ? R.pluck('id', invoice?.invoices as Invoice[])
            : [invoice?.id as string],
          balancePayment,
          request: paymentType,
          transport,
          subject: subject.value,
          message: replaceWithTargetPlaceholder(
            message.value,
            UI_PAYMENT_LINK_PLACEHOLDERS,
            PAYMENT_LINK_PLACEHOLDER,
          ),
          recipients:
            messageFormattingAreaRef.current?.formRecipients() as EmailEntityConfigRecipient[],
        }),
      )
      displayConversationCreationResult()
    }
  }

  const updateMessage = (text: string) => {
    message.setValue(text)
    messageFormattingAreaRef.current?.resetMessageState()
  }

  useEffect(() => {
    if (!client?.email) {
      dispatch(fetchClient({ clientId }))
    }
  }, [clientId])

  useEffect(() => {
    if (client?.email) {
      setSelectedRecipients([mapClientToClientChip(client)])
      updateMessage(normalizedMessageBody)
      subject.setValue(initialSubject)
    }
  }, [client?.email])

  useEffect(() => {
    updateMessage(normalizedMessageBody)
    subject.setValue(initialSubject)
  }, [transport, paymentType])

  return (
    <PuiDialog
      aria-labelledby="payment-dialog"
      classes={{ paper: classes.paper }}
      title={t('Common:REQUEST_PAYMENT')}
      onClose={onClose}
      {...rest}
    >
      <Grid container direction="column" ref={rootRef}>
        <Grid pb={2} pl={3} pr={5} pt={1}>
          <Grid container direction="column">
            <Grid item>
              <Text strong pb={1} variant="body2">
                {t('Dialogs:PAYMENT_REQUEST_DIALOG.RADIO_TITLE')}
              </Text>
            </Grid>
            <RadioGroup
              aria-label={t('Dialogs:PAYMENT_REQUEST_DIALOG.RADIO_TITLE')}
              className={classes.radioContainer}
              name="requestingPaymentType"
              value={paymentType}
              onChange={(_, value) =>
                setPaymentType(value as RequestPaymentType)
              }
            >
              <FormControlLabel
                classes={radioClasses}
                control={<Radio className={classes.radio} />}
                label={t('Common:PAYMENTS.PAYMENT')}
                value={RequestPaymentType.PAYMENT}
              />
              <FormControlLabel
                classes={radioClasses}
                control={<Radio className={classes.radio} />}
                label={t('Common:AUTHORIZATION')}
                value={RequestPaymentType.AUTHORIZATION}
              />
            </RadioGroup>
          </Grid>
          <ConversationTransportSelect
            allowedTransports={ALLOWED_TRANSPORTS}
            contactSlot={{ clientId }}
            transport={transport}
            onChange={setTransport}
          />

          <ConversationMessageFormattingArea
            hidePanel
            hidePlusButtonBlock
            classes={{
              messageFormattingArea: classes.messageFormattingArea,
            }}
            clientId={clientId}
            eventId={invoice?.event?.id}
            messageField={message}
            minEditorHeight={150}
            patientId={patientId}
            ref={messageFormattingAreaRef}
            subjectField={subject}
            to={to}
            toInputClasses={{ container: classes.toContainer }}
            transport={transport}
            {...rest}
          />
        </Grid>
        <Grid container className={classes.buttonsSection} p={2}>
          <ButtonWithLoader
            className={classes.sendButton}
            disabled={
              isLoading ||
              R.isEmpty(selectedRecipients) ||
              !transport ||
              !paymentType
            }
            loading={isLoading}
            onClick={handleSend}
          >
            {t('Common:SEND_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default PaymentRequestDialog
