import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, StackProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { PuiTooltip } from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import {
  CardPaymentMethod,
  PaymentMethod,
  PayPalPaymentMethod,
  ShippingAddress,
} from '~/api/graphql/generated/types'
import { ChewyMissingEntity } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyMissingEntity/ChewyMissingEntity'
import { getPrimary } from '~/utils/cvcClient'

import { ChewyPaymentMethodSelect } from '../ChewyPaymentMethodSelect/ChewyPaymentMethodSelect'
import { ChewyShippingAddressSelect } from '../ChewyShippingAddressSelect/ChewyShippingAddressSelect'

const StyledSelectContainerStack = styled(Stack)`
  align-items: flex-start;

  & .MuiFormControl-root {
    width: 295px;
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.brandPrimary};
  }
`

export type ChewyPaymentBodyProps = StackProps & {
  clientName: string
  paymentMethods: PaymentMethod[]
  shippingAddresses: ShippingAddress[]
}

export const ChewyPaymentBody = ({
  clientName,
  paymentMethods,
  shippingAddresses,
  ...props
}: ChewyPaymentBodyProps) => {
  const { t } = useTranslation(['Common'])

  // TODO: M1 consider moving up
  const cardAndPaypalPaymentMethods = useMemo(
    () =>
      paymentMethods.filter(
        (method): method is CardPaymentMethod | PayPalPaymentMethod =>
          method.__typename === 'CardPaymentMethod' ||
          method.__typename === 'PayPalPaymentMethod',
      ),
    [paymentMethods],
  )

  const [paymentMethodValue, setPaymentMethodValue] = useState(
    getPrimary(cardAndPaypalPaymentMethods),
  )

  const [shippingAddressValue, setShippingAddressValue] = useState(
    getPrimary(shippingAddresses),
  )

  useEffect(() => {
    setPaymentMethodValue(getPrimary(cardAndPaypalPaymentMethods))
  }, [cardAndPaypalPaymentMethods])

  useEffect(() => {
    setShippingAddressValue(getPrimary(shippingAddresses))
  }, [shippingAddresses])

  return (
    <Stack gap={2} {...props} alignItems="flex-start">
      {paymentMethodValue === null ? (
        <ChewyMissingEntity
          message={t('Common:PAYMENTS.NEEDS_TO_ADD_PAYMENT_METHOD', {
            clientName,
          })}
          tooltip={t('Common:PAYMENTS.NEEDS_TO_ADD_PAYMENT_METHOD_TOOLTIP')}
        />
      ) : (
        <StyledSelectContainerStack direction="row" gap={1}>
          <ChewyPaymentMethodSelect
            borderlessWhenDisabled
            disabled={cardAndPaypalPaymentMethods.length === 1}
            options={cardAndPaypalPaymentMethods}
            value={paymentMethodValue}
            onChange={setPaymentMethodValue}
          />
          <PuiTooltip
            tooltipText={t(
              'Common:PAYMENTS.NEEDS_TO_ADD_PAYMENT_METHOD_TOOLTIP',
            )}
          >
            <InfoIcon />
          </PuiTooltip>
        </StyledSelectContainerStack>
      )}

      {shippingAddressValue === null ? (
        <ChewyMissingEntity
          message={t('Common:PAYMENTS.NEEDS_TO_ADD_SHIPPING_ADDRESS', {
            clientName,
          })}
          tooltip={t('Common:PAYMENTS.NEEDS_TO_ADD_SHIPPING_ADDRESS_TOOLTIP')}
        />
      ) : (
        <StyledSelectContainerStack direction="row" gap={1}>
          <ChewyShippingAddressSelect
            borderlessWhenDisabled
            disabled={shippingAddresses.length === 1}
            options={shippingAddresses}
            value={shippingAddressValue}
            onChange={setShippingAddressValue}
          />
          <PuiTooltip
            tooltipText={t(
              'Common:PAYMENTS.NEEDS_TO_ADD_SHIPPING_ADDRESS_TOOLTIP',
            )}
          >
            <InfoIcon />
          </PuiTooltip>
        </StyledSelectContainerStack>
      )}
    </Stack>
  )
}
