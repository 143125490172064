import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import {
  clearClientBillingAddresses,
  clearTransactionInfo,
} from '~/store/actions/payments'
import {
  getIsPosPayInTransaction,
  getPosPayTransaction,
} from '~/store/reducers/payments'
import { ExtendPayment } from '~/types'

import PaymentForm from './PaymentForm'
// @ts-ignore
import RhapsodyPayment from './RhapsodyPayment'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    smallPaper: {
      width: 650,
      height: 270,
      maxWidth: 650,
    },
    hidden: {
      display: 'none',
    },
  }),
  { name: 'AddPaymentDialog' },
)

export interface AddPaymentDialogProps extends BasePuiDialogProps {
  ComponentProps?: any
  clientId: string | Nil
  depositExceedsInvoiceAmountRefund?: boolean
  isAdjustment?: boolean
  isInvoiceRefund?: boolean
  isRefund?: boolean
  isReverseGo?: boolean
  isReversePayment?: boolean
  isVoid?: boolean
  onOk?: () => void
  payment?: ExtendPayment
}

const AddPaymentDialog = ({
  open,
  onClose,
  payment,
  isInvoiceRefund = false,
  isAdjustment = false,
  depositExceedsInvoiceAmountRefund = false,
  isRefund = depositExceedsInvoiceAmountRefund,
  isReverseGo = false,
  isReversePayment = false,
  isVoid = false,
  clientId,
  ComponentProps,
  onOk,
}: AddPaymentDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const posPayTransaction = useSelector(getPosPayTransaction)
  const isRhapsodyPaymentInProgress = useSelector(getIsPosPayInTransaction)

  const handleClose = () => {
    dispatch(clearTransactionInfo())
    dispatch(clearClientBillingAddresses())
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      aria-labelledby="add-payment-dialog"
      classes={{
        paper: isRhapsodyPaymentInProgress ? classes.smallPaper : classes.paper,
      }}
      open={open}
      onClose={handleClose}
    >
      {isRhapsodyPaymentInProgress && (
        <RhapsodyPayment
          clientId={clientId}
          transaction={posPayTransaction}
          onOk={handleClose}
        />
      )}
      <Grid
        className={classNames({
          [classes.hidden]: isRhapsodyPaymentInProgress,
        })}
      >
        <PaymentForm
          ComponentProps={ComponentProps}
          clientId={clientId}
          depositExceedsInvoiceAmountRefund={depositExceedsInvoiceAmountRefund}
          isAdjustment={isAdjustment}
          isInvoiceRefund={isInvoiceRefund}
          isRefund={isRefund}
          isReverseGo={isReverseGo}
          isReversePayment={isReversePayment}
          isVoid={isVoid}
          payment={payment}
          onClose={handleClose}
          onOk={onOk}
        />
      </Grid>
    </PuiDialog>
  )
}

export default AddPaymentDialog
