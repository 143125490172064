import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ApiError, ButtonWithLoader, Utils } from '@pbt/pbt-ui-components'
import { formatMoney } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import { StatusError } from '~/components/elements/Status/Status'
import Typography from '~/components/elements/Typography/Typography'

const StyledButtonWithLoader = styled(ButtonWithLoader)`
  && {
    padding: ${({ theme }) => theme.spacing(0, 3)};
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 0;

  & .MuiCheckbox-root {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: ${({ theme }) => theme.spacing(1)};
    margin: 0;
  }
`

const StyledBalanceGrid = styled('div')`
  display: grid;
  grid-template-columns: [label] max-content [value] max-content;
  & > * {
    display: contents;
    & > :nth-child(2n) {
      justify-self: end;
      padding-left: ${({ theme }) => theme.spacing(1.5)};
    }
    & > label {
      cursor: pointer;
    }
  }
`

export type ChewyPaymentFooterProps = {
  accountBalance?: number
  canPlaceChewyOrder?: boolean
  clientName: string
  error?: ApiError
  isPaymentRequest?: boolean
  onMoveToCart: () => void
  onPlaceOrder: (applyAccountBalance: boolean) => void
  total: number
}

export const ChewyPaymentFooter = ({
  accountBalance,
  clientName,
  onPlaceOrder,
  onMoveToCart,
  total,
  isPaymentRequest = false,
  canPlaceChewyOrder = false,
  error,
}: ChewyPaymentFooterProps) => {
  const { t } = useTranslation(['Dialogs'])

  const [applyAccountBalance, setApplyAccountBalance] = useState(false)

  const totalDue = !applyAccountBalance
    ? total
    : Math.max(0, Utils.round(total - (accountBalance ?? 0), 2) ?? 0)

  const showAccountBalance = accountBalance && accountBalance > 0

  return (
    <Stack gap={1}>
      <Stack gap={1}>
        {isPaymentRequest && (
          <StatusError>
            {t('Dialogs:CHEWY_PAYMENT_DIALOG.PAYMENT_REQUEST_WARNING')}
          </StatusError>
        )}
        {showAccountBalance ? (
          <StyledBalanceGrid>
            {!canPlaceChewyOrder ? (
              <>
                <Typography.H3 color="grayGray1">
                  <div>
                    {t('Dialogs:CHEWY_PAYMENT_DIALOG.CHEWY_ORDER_TOTAL')}
                  </div>
                  <div>{formatMoney(total)}</div>
                </Typography.H3>
                <Typography.Paragraph as="span">
                  <div>
                    {t(
                      'Dialogs:CHEWY_PAYMENT_DIALOG.APPLY_CHEWY_ACCOUNT_BALANCE',
                    )}
                  </div>
                  <div>
                    {/* TODO: M1 verify negative value - must be in () */}
                    {formatMoney(-accountBalance)}
                  </div>
                </Typography.Paragraph>
              </>
            ) : (
              <>
                <Typography.Paragraph>
                  <div>
                    {t('Dialogs:CHEWY_PAYMENT_DIALOG.CHEWY_ORDER_TOTAL')}
                  </div>
                  <div>{formatMoney(total)}</div>
                </Typography.Paragraph>
                <Typography.Paragraph as="span">
                  {/* TODO: M1 switch to Design Systems Checkbox */}
                  <StyledFormControlLabel
                    disableTypography
                    control={
                      <Checkbox
                        checked={applyAccountBalance}
                        inputProps={{
                          'aria-label': 'apply-balance',
                          id: 'apply-balance-checkbox',
                        }}
                        onChange={(event) =>
                          setApplyAccountBalance(event.target.checked)
                        }
                      />
                    }
                    label={t(
                      'Dialogs:CHEWY_PAYMENT_DIALOG.APPLY_CHEWY_ACCOUNT_BALANCE',
                    )}
                  />
                  {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
                  <label htmlFor="apply-balance-checkbox">
                    {/* TODO: M1 verify negative value - must be in () */}
                    {formatMoney(-accountBalance)}
                  </label>
                </Typography.Paragraph>
                <Typography.H3 color="grayGray1">
                  <div>{t('Dialogs:CHEWY_PAYMENT_DIALOG.CHEWY_TOTAL_DUE')}</div>
                  <div>{formatMoney(totalDue)}</div>
                </Typography.H3>
              </>
            )}
          </StyledBalanceGrid>
        ) : (
          <Typography.H3>
            {t('Dialogs:CHEWY_PAYMENT_DIALOG.CHEWY_TOTAL')}:{' '}
            {formatMoney(total)}
          </Typography.H3>
        )}

        <Typography.Paragraph>
          {canPlaceChewyOrder
            ? t(
                'Dialogs:CHEWY_PAYMENT_DIALOG.BEFORE_PROCEEDING_CONFIRM_PET_PARENT_AUTHORIZES_CHARGE',
              )
            : t(
                'Dialogs:CHEWY_PAYMENT_DIALOG.YOU_CANNOT_PROCEED_WITH_NO_PAYMENT_METHOD_ON_FILE',
                { clientName },
              )}
        </Typography.Paragraph>

        {Boolean(error) && (
          <StatusError>
            {t('Dialogs:CHEWY_PAYMENT_DIALOG.GENERAL_PAYMENT_FAILURE')}
          </StatusError>
        )}
      </Stack>
      <Stack direction="row" gap={2}>
        {canPlaceChewyOrder && (
          <StyledButtonWithLoader
            onClick={() => onPlaceOrder(applyAccountBalance)}
          >
            {t('Dialogs:CHEWY_PAYMENT_DIALOG.PLACE_CHEWY_ORDER')}
          </StyledButtonWithLoader>
        )}
        <StyledButtonWithLoader
          color={
            isPaymentRequest || !canPlaceChewyOrder ? undefined : 'secondary'
          }
          onClick={onMoveToCart}
        >
          {t('Dialogs:CHEWY_PAYMENT_DIALOG.MOVE_ITEMS_TO_CHEWY_CART')}
        </StyledButtonWithLoader>
      </Stack>
    </Stack>
  )
}
