import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material'
import { Collapse, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateUtils,
  moment,
  Nil,
  NumberUtils,
  Text,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'
import { formatMoney } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import { Payment as GraphQlPayment } from '~/api/graphql/generated/types'
import MenuDropdown from '~/components/common/inputs/MenuDropdown'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import PaymentTypes from '~/constants/paymentTypes'
import {
  fetchClientBillingActivity,
  getBillingActivitySummary,
} from '~/store/duck/clientBillingActivityData'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  BatchInvoice,
  ExtendPayment,
  InvoiceOrEstimate,
  InvoiceTotals,
  Payment,
} from '~/types'
import { getPaymentPaidPersonName } from '~/utils/finance'
import { getPaymentMethodString, getPaymentType } from '~/utils/paymentUtils'
import useDialog from '~/utils/useDialog'

import {
  formatMoneyRange,
  formatPositiveMoney,
  getIsBatchInvoice,
} from './invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    totalContainer: {
      width: 500,
      backgroundColor: theme.colors.tableLeftColumnBackground,
      borderLeft: theme.constants.tabBorder,
    },
    row: {
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.totalBorder,
      },
    },
    leftColumn: {
      borderRight: theme.constants.totalBorder,
    },
    expander: {
      cursor: 'pointer',
    },
    collapse: {
      paddingLeft: theme.spacing(3),
    },
    menuDropdownButton: {
      fontWeight: 500,
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'left',
    },
    iconButton: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'InvoiceTotalsSection' },
)

const MINIMUM_AMOUNT_OF_PAYMENTS_TO_COLLAPSE = 3

export interface InvoiceTotalsSectionProps {
  clientId: string | Nil
  includeServiceFee: boolean
  invoice: InvoiceOrEstimate | BatchInvoice
  isEstimate: boolean
  isVoidedState?: boolean
  navigateToRefundLanding?: () => void
  setIncludeServiceFee: (arg: boolean) => void
  setInvoice: (invoice: InvoiceOrEstimate) => void
  totals: InvoiceTotals
}

const InvoiceTotalsSection = ({
  clientId,
  invoice,
  setInvoice,
  isEstimate,
  includeServiceFee,
  setIncludeServiceFee,
  navigateToRefundLanding,
  totals,
  isVoidedState = false,
}: InvoiceTotalsSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hasServiceFee = Boolean(invoice.serviceFee)
  const { t } = useTranslation(['Common', 'Invoices'])

  const ServiceFeeOptions = [
    {
      name: t('Invoices:WITH_SERVICE_FEE'),
      value: true,
    },
    {
      name: t('Invoices:WITHOUT_SERVICE_FEE'),
      value: false,
    },
  ]

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const isDiscountReasonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DISCOUNT_REASON),
  )
  const isNewInvoiceFooterEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_NEW_INVOICE_FOOTER),
  )

  const [openPayment] = useDialog(DialogNames.PAYMENT_DETAILS)
  const [openRequiredDepositDialog] = useDialog(DialogNames.REQUIRED_DEPOSIT)

  const [totalExpanded, setTotalExpanded] = useState(false)
  const [isPaymentsCollapsed, setIsPaymentsCollapsed] = useState(false)

  const deposit = includeServiceFee
    ? totals.requiredDeposit
    : totals.requiredDepositWithoutFee

  const totalAmountNoFee =
    isChargeSheetEnabled && !isEstimate
      ? R.prop('dueToPayNoFee', invoice)
      : R.prop('totalWithoutFee', totals)
  const totalAmountFee =
    isChargeSheetEnabled && !isEstimate
      ? R.prop('dueToPay', invoice)
      : R.prop('total', totals)

  const totalAmount = includeServiceFee ? totalAmountFee : totalAmountNoFee

  const hasDeposit = isEstimate && !R.isNil(invoice.requiredDeposit)
  const completedPayments = invoice.payments || []
  const showTotalPaidLabel =
    completedPayments.length >= MINIMUM_AMOUNT_OF_PAYMENTS_TO_COLLAPSE
  const isBatchInvoice = getIsBatchInvoice(invoice)

  const showServiceFeeOptions = isChargeSheetEnabled
    ? invoice.dueToPayNoFee !== 0 && hasServiceFee
    : !invoice.paid && hasServiceFee && !isVoidedState

  useEffect(() => {
    if (completedPayments) {
      setIsPaymentsCollapsed(showTotalPaidLabel)
    }
  }, [completedPayments])

  const overageAmount = completedPayments.reduce(
    (totalOverage, currentPayment) =>
      currentPayment.split &&
      currentPayment.splitAmount &&
      currentPayment.amount
        ? totalOverage + (currentPayment.amount - currentPayment.splitAmount)
        : totalOverage,
    0,
  )
  useEffect(() => {
    if (clientId && isNewInvoiceFooterEnabled) {
      dispatch(fetchClientBillingActivity(clientId))
    }
  }, [clientId])

  const clientBillingActivitySummary = useSelector(getBillingActivitySummary)

  const showClientBalance = invoice.paid
    ? invoice.paidDate && invoice.balanceAfterPaid
    : isNewInvoiceFooterEnabled
  const clientBalanceDate = invoice.paid
    ? invoice.paidDate
    : moment().toDate().toISOString()
  const clientBalance = invoice.paid
    ? invoice.balanceAfterPaid
    : clientBillingActivitySummary.balance

  return (
    <Grid
      container
      item
      className={classes.totalContainer}
      direction="column"
      wrap="nowrap"
    >
      <Grid container item className={classes.row}>
        <Grid item className={classes.leftColumn} py={1.5} xs={8}>
          <Collapse className={classes.collapse} in={totalExpanded}>
            {R.prop('showBalance', invoice) &&
              isChargeSheetEnabled &&
              !isEstimate && (
                <Text variant="subheading3">
                  {t('Common:PREVIOUS_BALANCE')}
                </Text>
              )}
            <Text variant="subheading3">{t('Common:SUBTOTAL')}</Text>
            <TextWithTooltip
              tooltipText={
                isDiscountReasonEnabled && invoice.discountReasonName
              }
              variant="subheading3"
            >
              {t('Common:DISCOUNT')}
            </TextWithTooltip>
            <Text variant="subheading3">{t('Common:TAX')}</Text>
          </Collapse>
          <Grid
            container
            item
            alignItems="center"
            className={classes.expander}
            onClick={() => setTotalExpanded(!totalExpanded)}
          >
            {totalExpanded ? <KeyboardArrowUp /> : <KeyboardArrowRight />}
            <Text inline strong variant="body2">
              {isBatchInvoice
                ? t('Invoices:GRAND_TOTAL_INVOICES', {
                    invoicesLength: invoice.invoices?.length,
                  })
                : t('Common:INVOICE_TOTAL')}
            </Text>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          pr={3}
          wrap="nowrap"
          xs={4}
        >
          <Collapse in={totalExpanded}>
            {R.prop('showBalance', invoice) &&
              isChargeSheetEnabled &&
              !isEstimate && (
                <Text align="right" variant="subheading3">
                  {NumberUtils.formatMoney(R.prop('prevBalance', invoice))}
                </Text>
              )}
            <Text align="right" variant="subheading3">
              {formatMoneyRange(
                isChargeSheetEnabled && !isEstimate
                  ? invoice.subtotal
                  : totals.totalWithoutTax,
              )}
            </Text>
            <Text align="right" variant="subheading3">
              {formatMoneyRange(
                isChargeSheetEnabled && !isEstimate
                  ? R.prop('additionalDiscount', invoice) +
                      (R.prop('totalDiscount', invoice) || 0)
                  : R.prop('totalDiscountWithAdditional', totals),
              )}
            </Text>
            <Text align="right" variant="subheading3">
              {formatMoneyRange(
                isChargeSheetEnabled && !isEstimate
                  ? R.prop('totalTax', invoice)
                  : totals.totalTax,
              )}
            </Text>
          </Collapse>
          <Text strong align="right" variant="subheading2">
            {formatMoneyRange(
              isChargeSheetEnabled && !isEstimate
                ? invoice.amountNoFee
                : totals.totalWithoutFee,
            )}
          </Text>
        </Grid>
      </Grid>
      {showServiceFeeOptions && (
        <Grid container item alignItems="center" className={classes.row}>
          <Grid container item className={classes.leftColumn} pl={3} xs={8}>
            <MenuDropdown
              linkButton
              classes={{
                button: classes.menuDropdownButton,
              }}
              items={ServiceFeeOptions}
              title={
                ServiceFeeOptions.find(
                  (option) => option.value === includeServiceFee,
                )?.name
              }
              onSelected={setIncludeServiceFee}
            />
          </Grid>
          <Grid item pr={3} xs={4}>
            <Text strong align="right" variant="subheading2">
              {formatMoneyRange(totalAmount)}
            </Text>
          </Grid>
        </Grid>
      )}
      {hasDeposit && (
        <Grid container item alignItems="center" className={classes.row}>
          <Grid
            container
            item
            alignItems="center"
            className={classes.leftColumn}
            pl={3}
            wrap="nowrap"
            xs={8}
          >
            <Text strong variant="subheading3">
              {t('Invoices:REQUIRED_DEPOSIT_AMOUNT', {
                amount: NumberUtils.toPercentFormat(invoice.requiredDeposit, 2),
              })}
            </Text>
            <IconButton
              className={classes.iconButton}
              size="large"
              onClick={() => {
                openRequiredDepositDialog({
                  estimate: invoice as InvoiceOrEstimate,
                  onChange: setInvoice,
                })
              }}
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item pr={3} xs={4}>
            <Text strong align="right" variant="subheading2">
              {formatMoneyRange(deposit)}
            </Text>
          </Grid>
        </Grid>
      )}
      {!R.isEmpty(completedPayments) && (
        <Grid className={classes.row}>
          {showTotalPaidLabel && (
            <Grid
              container
              item
              alignItems="center"
              className={classNames(classes.row, classes.expander)}
              onClick={() => setIsPaymentsCollapsed(!isPaymentsCollapsed)}
            >
              <Grid
                container
                item
                alignItems="center"
                className={classes.leftColumn}
                py={1.5}
                wrap="nowrap"
                xs={8}
              >
                {isPaymentsCollapsed ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowUp />
                )}
                <Text strong variant="subheading3">
                  {t('Invoices:TOTAL_PAID_COMPLETED_PAYMENTS', {
                    completedPayments: completedPayments.length,
                  })}
                </Text>
              </Grid>
              <Grid item pr={3} xs={4}>
                <Text align="right" variant="subheading2">
                  {formatMoneyRange(invoice.paidAmount, true)}
                </Text>
              </Grid>
            </Grid>
          )}
          <Collapse in={!isPaymentsCollapsed}>
            {completedPayments.map((payment: GraphQlPayment | Payment) => {
              const paidPersonName = getPaymentPaidPersonName(payment)

              const paymentType = getPaymentType(payment)

              const signedSplitAmount =
                R.prop('signedSplitAmount', payment) || 0
              const isReverseCharge =
                isChargeSheetEnabled && !isEstimate
                  ? signedSplitAmount > 0
                  : paymentType === PaymentTypes.REVERSE_CHARGE

              const isCreditAdjustment = paymentType === PaymentTypes.ADJUSTMENT

              const actionText = isCreditAdjustment
                ? t('Common:CREDIT_ADJUSTMENT')
                : isReverseCharge
                ? paidPersonName
                  ? `${t('Common:PAYMENTS.REFUNDED_TO')} ${paidPersonName}`
                  : t('Common:PAYMENTS.REFUNDED')
                : paidPersonName
                ? `${t('Common:PAYMENTS.PAID_BY')} ${paidPersonName}`
                : null

              const money =
                !isNewInvoiceFooterEnabled &&
                (('splitted' in payment && payment.splitted) || payment.split)
                  ? payment.splitAmount
                  : payment.amount

              return (
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classes.row}
                  key={payment.id}
                >
                  <Grid item className={classes.leftColumn} pl={3} xs={8}>
                    <Text strong variant="subheading3">
                      {actionText || t('Invoices:PAID_INFO_IS_MISSING')}
                    </Text>
                    <Text
                      inline
                      link
                      variant="body2"
                      onClick={() => {
                        openPayment({
                          payment: payment as ExtendPayment,
                          clientId,
                          isPossibleToViewInvoice: true,
                          forceNotNavigateToBalanceAfterRefund: true,
                          invoiceId: invoice.id,
                          navigateToRefundLanding,
                        })
                      }}
                    >
                      {`${DateUtils.formatDate(
                        payment.creationDate,
                      )} | ${getPaymentMethodString(payment)}`}
                    </Text>
                  </Grid>
                  <Grid item pr={3} xs={4}>
                    <Text strong align="right" variant="subheading2">
                      {isReverseCharge
                        ? formatPositiveMoney(money)
                        : formatMoneyRange(money, true)}
                    </Text>
                    {Boolean(payment.serviceFeeIncAmount) && (
                      <Text align="right" variant="subheading3">
                        {t('Invoices:INCLUDING_SERVICE_FEE', {
                          amount: formatMoneyRange(payment.serviceFeeIncAmount),
                        })}
                      </Text>
                    )}
                  </Grid>
                </Grid>
              )
            })}
          </Collapse>
        </Grid>
      )}
      {isNewInvoiceFooterEnabled && (
        <>
          <Grid container item alignItems="center" className={classes.row}>
            <Grid item className={classes.leftColumn} pl={3} xs={8}>
              <Text strong variant="subheading3">
                Invoice total due
              </Text>
            </Grid>
            <Grid item pr={3} xs={4}>
              <Text strong align="right" variant="subheading2">
                {formatPositiveMoney(invoice.dueToPayNoFee)}
              </Text>
            </Grid>
          </Grid>
          {overageAmount > 0 && (
            <Grid container item alignItems="center" className={classes.row}>
              <Grid item className={classes.leftColumn} pl={3} xs={8}>
                <Text variant="subheading3">
                  Payment overage applied to account balance
                </Text>
              </Grid>
              <Grid item pr={3} xs={4}>
                <Text align="right" variant="subheading2">
                  {formatPositiveMoney(overageAmount)}
                </Text>
              </Grid>
            </Grid>
          )}
          {showClientBalance && (
            <Grid container item alignItems="center" className={classes.row}>
              <Grid item className={classes.leftColumn} pl={3} xs={8}>
                <Text variant="subheading3">
                  {t('Invoices:UPDATED_ACCOUNT_BALANCE_LABEL', {
                    date: DateUtils.formatDate(clientBalanceDate),
                  })}
                </Text>
              </Grid>
              <Grid item pr={3} xs={4}>
                <Text align="right" variant="subheading2">
                  {formatMoney(clientBalance)}
                </Text>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export default InvoiceTotalsSection
