import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  AlertIconType,
  BasePuiDialogProps,
  CustomFieldValidatorState,
  PuiAlert,
  PuiTextField,
  useFields,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'

import EnumRadioGroup from '~/components/common/inputs/EnumRadioGroup'
import { getInternalCancellationReasons } from '~/store/reducers/constants'

const validateOtherField = (
  otherReasonId: string,
  {
    state: { appointmentCancellationReason, otherReason },
  }: CustomFieldValidatorState,
) => {
  if (appointmentCancellationReason === otherReasonId)
    return Validators.notEmptyFormField(otherReason)

  return true
}

const StyledAlert = styled(PuiAlert)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 500,
    maxWidth: 500,
    padding: theme.spacing(1),
  },
}))

export interface AppointmentCancellationReasonDialogProps
  extends BasePuiDialogProps {
  onCancel?: () => void
  onProceed: (
    appointmentCancellationReasonId: string,
    appointmentCancellationReasonName: string,
  ) => void
}

export const AppointmentCancellationReasonDialog = ({
  open,
  onProceed,
  onClose,
  onCancel,
  ...rest
}: AppointmentCancellationReasonDialogProps) => {
  const { t } = useTranslation(['Dialogs', 'Common'])
  const internalCancellationReasons = useSelector(
    getInternalCancellationReasons,
  )

  const otherReasonId = Utils.findConstantIdByName(
    'Other',
    internalCancellationReasons,
  )

  const { fields, validate } = useFields(
    [
      {
        name: 'appointmentCancellationReason',
        validators: ['required'],
        type: 'select',
      },
      {
        name: 'otherReason',
        validators: [
          {
            validator: (state) => validateOtherField(otherReasonId, state),
            validatorName: 'required',
          },
        ],
      },
    ],
    false,
  )

  const { appointmentCancellationReason, otherReason } = fields

  const handleProceed = () => {
    if (!validate()) return
    onProceed(appointmentCancellationReason.value, otherReason.value || null)
    if (!onClose) return
    onClose()
  }

  const handleCancel = () => {
    onCancel?.()
    onClose?.()
  }

  return (
    <StyledAlert
      aria-labelledby="appointment-cancellation-reason-dialog"
      cancelButtonText={t('Common:GO_BACK')}
      content={
        <>
          <EnumRadioGroup
            Constant={internalCancellationReasons}
            field={appointmentCancellationReason}
          />
          {appointmentCancellationReason.value === otherReasonId && (
            <Box pl={4} width="100%">
              <PuiTextField
                field={otherReason}
                inputProps={{ maxLength: 1500 }}
                label={t(
                  'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_FOR_CANCELLATION',
                )}
              />
            </Box>
          )}
        </>
      }
      iconType={AlertIconType.WARN}
      message={t(
        'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.WHY_IS_THE_APPOINTMENT_BEING_CANCELED',
      )}
      okButtonText={t(
        'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.CANCEL_APPOINTMENT',
      )}
      open={open}
      onCancel={handleCancel}
      onClose={handleCancel}
      onOk={handleProceed}
      {...rest}
    />
  )
}
