import { useSelector } from 'react-redux'
import { Nil, Utils } from '@pbt/pbt-ui-components'

import { PaymentMethod, ShippingAddress } from '~/api/graphql/generated/types'
import { AddPaymentDialogProps } from '~/components/dashboard/invoices/payment/AddPaymentDialog'
import { ChewyPaymentDialogProps } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyPaymentDialog/ChewyPaymentDialog'
import { PaymentRequestDialogProps } from '~/components/dashboard/invoices/payment/PaymentRequestDialog'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'

type UseChewyPaymentProps = {
  clientId: string | Nil
}

export const usePaymentController = ({ clientId }: UseChewyPaymentProps) => {
  const client = useSelector(getUser(clientId))
  const [openPaymentRequestDialog] = useDialog(DialogNames.PAYMENT_REQUEST)
  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const [openChewyPaymentDialog] = useDialog(DialogNames.CHEWY_PAYMENT_DIALOG)

  const isIpoM1CheckoutEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M1_CHECKOUT),
  )

  const clientName = Utils.getPersonString(client)

  // TODO: M1: integrate API. Mock data below.
  const accountBalance = 0
  const error = undefined
  const paymentMethods: PaymentMethod[] = []
  const shippingAddresses: ShippingAddress[] = []
  const onMoveToCart = () => {}
  const onPlaceOrder = () => {}
  const total = 0

  return {
    paymentRequest: ({
      paymentRequestDialogProps,
    }: {
      paymentRequestDialogProps: Omit<PaymentRequestDialogProps, 'open'>
    }) => {
      const chewyPaymentDialogProps: Omit<ChewyPaymentDialogProps, 'open'> = {
        accountBalance,
        clientName,
        error,
        isPaymentRequest: true,
        onMoveToCart,
        onPlaceOrder,
        paymentMethods,
        shippingAddresses,
        total,
      }
      if (isIpoM1CheckoutEnabled) {
        openChewyPaymentDialog(chewyPaymentDialogProps)
      } else {
        openPaymentRequestDialog(paymentRequestDialogProps)
      }
    },
    addPayment: ({
      addPaymentDialogProps,
    }: {
      addPaymentDialogProps: Omit<AddPaymentDialogProps, 'open'>
    }) => {
      const chewyPaymentDialogProps: Omit<ChewyPaymentDialogProps, 'open'> = {
        accountBalance,
        clientName,
        error,
        isPaymentRequest: false,
        onMoveToCart,
        onPlaceOrder,
        paymentMethods,
        shippingAddresses,
        total,
      }
      if (isIpoM1CheckoutEnabled) {
        openChewyPaymentDialog(chewyPaymentDialogProps)
      } else {
        openAddPaymentDialog(addPaymentDialogProps)
      }
    },
  }
}
