import { Theme } from '@mui/material'
import * as R from 'ramda'
import { isNil } from 'ramda'
import {
  Business,
  Constant,
  LanguageUtils,
  NamedEntity,
  Nil,
  NumberUtils,
  SentenceFormatter,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  GoPayment as GraphQlGoPayment,
  Payment as GraphQlPayment,
} from '~/api/graphql/generated/types'
import InvoiceType from '~/constants/InvoiceType'
import PaymentType, {
  getRawPaymentTypeMultiplier,
  PAYMENT_TRANSACTION_STATE,
  PaymentLabel,
  PaymentTypesLabel,
} from '~/constants/paymentTypes'
import { RhapsodyGoPaymentMethod } from '~/constants/RhapsodyGoPaymentMethod'
import { AUTHORIZED, VOIDED } from '~/constants/rhapsodyGoPaymentsStates'
import RhapsodyGoPreferences from '~/constants/rhapsodyGoPreferences'
import i18n from '~/locales/i18n'
import {
  BalanceListEntryItem,
  ExtendPayment,
  Invoice,
  InvoiceOrEstimate,
  Payment,
  RhapsodyGoPreference,
  RhapsodyPayConfig,
} from '~/types'

const negativeFormattingPaymentTypes = new Set([
  PaymentType.PAYMENT,
  PaymentType.AUTHORIZATION,
  PaymentType.CREDIT,
  PaymentType.DEPOSIT,
])

const balanceDecreasingPayments = [
  PaymentType.PAYMENT,
  PaymentType.AUTHORIZATION,
  PaymentType.CREDIT,
]

export const checkIsVoid = ({ type, transactionStateName, state }: Payment) =>
  [PaymentType.GO_PAYMENT, PaymentType.GO_STRIPE_PAYMENT].includes(type) &&
  [transactionStateName, state].includes(VOIDED)

export const checkIsAuth = ({
  type,
  transactionStateName,
  state,
  paymentMethod,
}: Payment) =>
  [state, transactionStateName].includes(AUTHORIZED) &&
  [PaymentType.GO_PAYMENT, PaymentType.GO_STRIPE_PAYMENT].includes(type) &&
  paymentMethod === RhapsodyGoPaymentMethod.CREDIT_CARD

export const checkIsDebitAdjustment = ({ paymentType, amount }: Payment) =>
  paymentType === PaymentType.ADJUSTMENT && amount && amount > 0

export const checkIsCreditAdjustment = ({ paymentType, amount }: Payment) =>
  paymentType === PaymentType.ADJUSTMENT && amount && amount < 0

export const getPaymentRowColoring = (
  theme: Theme,
  PaymentTransactionState: Constant[],
  item: Payment,
) => {
  const ErrorType = Utils.findConstantIdByName(
    PAYMENT_TRANSACTION_STATE.ERROR,
    PaymentTransactionState,
  )

  if (item.transactionState && item.transactionState === ErrorType) {
    return theme.colors.important
  }
  const isVoid = checkIsVoid(item)
  const isRefund = item.paymentType === PaymentType.REVERSE_CHARGE
  const isUndo = item.paymentType === PaymentType.UNDO

  if (isRefund || isUndo || isVoid || checkIsDebitAdjustment(item)) {
    return theme.colors.important
  }

  if (
    item.paymentType &&
    (balanceDecreasingPayments.includes(item.paymentType) ||
      checkIsCreditAdjustment(item))
  ) {
    return theme.colors.success
  }

  return null
}

export const getAmountLabel = (
  item: Payment | BalanceListEntryItem,
  includeFees?: boolean,
) => {
  const amountWithNoFee =
    // @ts-ignore
    item.amountNoFee ?? item.amount - item.serviceFeeIncAmount
  const amount = includeFees ? item.amount : amountWithNoFee

  const payment = item as Payment

  if (R.isNil(amount) || Number.isNaN(amount)) {
    return ''
  }

  if (checkIsAuth(payment)) {
    return i18n.t('Common:CLICK_TO_SETTLE')
  }

  const isVoid = checkIsVoid(payment)

  if (
    payment.paymentType &&
    amount &&
    negativeFormattingPaymentTypes.has(payment.paymentType) &&
    !isVoid
  ) {
    return NumberUtils.formatMoney(amount, true)
  }

  return NumberUtils.formatMoney(Math.abs(amount))
}

export const getPaymentTypeLabel = (item: Payment) => {
  if (checkIsVoid(item)) {
    return i18n.t('Common:VOID')
  }
  if (checkIsAuth(item)) {
    return i18n.t('Common:AUTHORIZATION')
  }
  if (item.paymentType === PaymentType.ADJUSTMENT) {
    return item.amount && item.amount < 0
      ? `${i18n.t('Common:ADJUSTMENT')}: ${i18n.t('Common:CREDIT')}`
      : `${i18n.t('Common:ADJUSTMENT')}: ${i18n.t('Common:DEBIT')}`
  }
  const paymentTypeName = item.paymentType || item.type

  return PaymentTypesLabel[paymentTypeName] || paymentTypeName
}

export const getDerrivedPaymentType = ({
  paymentType,
  isRefund,
  isReversal,
  isVoid,
  isAuth,
  isCreditAdjustment,
  isDebitAdjustment,
  isUndo,
  isIpoM0VoidAndPaymentReversalFTEnabled,
}: {
  isAuth: boolean
  isCreditAdjustment: boolean | 0 | Nil
  isDebitAdjustment: boolean | 0 | Nil
  isIpoM0VoidAndPaymentReversalFTEnabled: boolean
  isRefund: boolean
  isReversal: boolean
  isUndo: boolean
  isVoid: boolean
  paymentType: PaymentType | Nil
}) => {
  const specificPaymentLabels = [
    { consition: isReversal, value: PaymentType.REVERSE_CHARGE },
    { condition: isRefund, value: PaymentType.REFUND },
    { condition: isVoid, value: PaymentType.VOID },
    { condition: isAuth, value: PaymentType.AUTHORIZATION },
    { condition: isCreditAdjustment, value: PaymentType.ADJUSTMENT_CREDIT },
    { condition: isDebitAdjustment, value: PaymentType.ADJUSTMENT_DEBIT },
    {
      condition: isUndo,
      value: isIpoM0VoidAndPaymentReversalFTEnabled
        ? PaymentType.VOID
        : PaymentType.UNDO,
    },
  ]
  const specificLabel = specificPaymentLabels.find(({ condition }) => condition)
  return specificLabel ? specificLabel.value : paymentType
}

export const getPaymentMethodLabel = (item: GraphQlPayment) => {
  if (item.type?.name === PaymentType.ADJUSTMENT) {
    return item.amount && item.amount < 0
      ? `${i18n.t('Common:ADJUSTMENT')}: ${i18n.t('Common:CREDIT')}`
      : `${i18n.t('Common:ADJUSTMENT')}: ${i18n.t('Common:DEBIT')}`
  }
  return item.method?.name || ''
}

export const isRhapsodyGoPreferencesFilledUp = (
  goPreferences: RhapsodyGoPreference,
) =>
  Object.values(RhapsodyGoPreferences).every(
    (preference) => goPreferences[preference],
  )

export const isRhapsodyGoAvailableForPractice = (
  business: Business | Nil,
  rhapsodyPayConfig: RhapsodyPayConfig | Nil,
) => {
  const { goOption, goPreferences } = rhapsodyPayConfig || {}
  const isValidGoPreferences =
    goPreferences && isRhapsodyGoPreferencesFilledUp(goPreferences)
  return Boolean(business?.posPayEnabled && goOption && isValidGoPreferences)
}

export const getPaymentDetailsLabelsString = (
  paymentTypeLabel: PaymentType | Nil,
) => {
  const AmountLabelRawMap: Partial<Record<PaymentType, string>> = {
    [PaymentType.PAYMENT]: i18n.t('Common:PAYMENTS.PAID'),
    [PaymentType.ADJUSTMENT_CREDIT]: i18n.t(
      'Common:PAYMENTS.ADJUSTMENT_CREDIT_AMOUNT',
    ),
    [PaymentType.ADJUSTMENT_DEBIT]: i18n.t(
      'Common:PAYMENTS.ADJUSTMENT_DEBIT_AMOUNT',
    ),
    [PaymentType.CREDIT]: i18n.t('Common:PAYMENTS.CREDIT_AMOUNT'),
    [PaymentType.REVERSE_CHARGE]: i18n.t(
      'Common:PAYMENTS.REVERSE_CHARGE_AMOUNT',
    ),
    [PaymentType.DEPOSIT]: i18n.t('Common:PAYMENTS.DEPOSIT_AMOUNT'),
    [PaymentType.REFUND]: i18n.t('Common:PAYMENTS.REFUNDED'),
    [PaymentType.VOID]: i18n.t('Common:PAYMENTS.VOIDED'),
    [PaymentType.AUTHORIZATION]: i18n.t('Common:PAYMENTS.AUTHORIZATION'),
    [PaymentType.UNDO]: i18n.t('Common:PAYMENTS.UNDO'),
    [PaymentType.IMPORTED]: i18n.t('Common:PAYMENTS.PAID'),
  }

  const ToLabelMap: Partial<Record<PaymentType, string>> = {
    [PaymentType.PAYMENT]: i18n.t('Common:PAYMENTS.PAID_BY'),
    [PaymentType.CREDIT]: i18n.t('Common:PAYMENTS.CREDIT_TO'),
    [PaymentType.REVERSE_CHARGE]: i18n.t('Common:PAYMENTS.REVERSE_CHARGE_TO'),
    [PaymentType.DEPOSIT]: i18n.t('Common:PAYMENTS.PAID_BY'),
    [PaymentType.VOID]: i18n.t('Common:PAYMENTS.VOIDED_BY'),
    [PaymentType.AUTHORIZATION]: i18n.t('Common:PAYMENTS.PAID_BY'),
  }

  const RecordedByLabelMap: Partial<Record<PaymentType, string>> = {
    [PaymentType.PAYMENT]: i18n.t('Common:PAYMENTS.RECORDED_BY'),
    [PaymentType.ADJUSTMENT_CREDIT]: i18n.t('Common:PAYMENTS.RECORDED_BY'),
    [PaymentType.ADJUSTMENT_DEBIT]: i18n.t('Common:PAYMENTS.RECORDED_BY'),
    [PaymentType.CREDIT]: i18n.t('Common:PAYMENTS.RECORDED_BY'),
    [PaymentType.DEPOSIT]: i18n.t('Common:PAYMENTS.RECORDED_BY'),
    [PaymentType.REFUND]: i18n.t('Common:PAYMENTS.REFUNDED_BY'),
    [PaymentType.AUTHORIZATION]: i18n.t('Common:PAYMENTS.RECORDED_BY'),
    [PaymentType.UNDO]: i18n.t('Common:PAYMENTS.UNDO_BY'),
    [PaymentType.IMPORTED]: i18n.t('Common:PAYMENTS.RECORDED_BY'),
  }

  const MethodLabelMap: Partial<Record<PaymentType, string>> = {
    [PaymentType.PAYMENT]: i18n.t('Common:PAYMENTS.PAYMENT_METHOD'),
    [PaymentType.CREDIT]: i18n.t('Common:PAYMENTS.CREDIT_METHOD'),
    [PaymentType.REVERSE_CHARGE]: i18n.t(
      'Common:PAYMENTS.REVERSE_CHARGE_METHOD',
    ),
    [PaymentType.DEPOSIT]: i18n.t('Common:PAYMENTS.PAYMENT_METHOD'),
    [PaymentType.AUTHORIZATION]: i18n.t('Common:PAYMENTS.PAYMENT_METHOD'),
  }

  const DateLabelMap: Partial<Record<PaymentType, string>> = {
    [PaymentType.PAYMENT]: i18n.t('Common:PAYMENTS.PAYMENT_DATE_TIME'),
    [PaymentType.ADJUSTMENT_CREDIT]: i18n.t(
      'Common:PAYMENTS.ADJUSTMENT_CREDIT_DATE_TIME',
    ),
    [PaymentType.ADJUSTMENT_DEBIT]: i18n.t(
      'Common:PAYMENTS.ADJUSTMENT_DEBIT_DATE_TIME',
    ),
    [PaymentType.CREDIT]: i18n.t('Common:PAYMENTS.CREDIT_DATE_TIME'),
    [PaymentType.REVERSE_CHARGE]: i18n.t(
      'Common:PAYMENTS.REVERSE_CHARGE_DATE_TIME',
    ),
    [PaymentType.DEPOSIT]: i18n.t('Common:PAYMENTS.DEPOSIT_DATE_TIME'),
    [PaymentType.REFUND]: i18n.t('Common:PAYMENTS.REFUND_DATE_TIME'),
    [PaymentType.VOID]: i18n.t('Common:PAYMENTS.VOID_DATE_TIME'),
    [PaymentType.AUTHORIZATION]: i18n.t('Common:PAYMENTS.PAYMENT_DATE_TIME'),
    [PaymentType.UNDO]: i18n.t('Common:PAYMENTS.UNDO_DATE_TIME'),
    [PaymentType.IMPORTED]: i18n.t('Common:PAYMENTS.IMPORT_DATE_TIME'),
  }

  const CashReceivedMap: Partial<Record<PaymentType, string>> = {
    [PaymentType.PAYMENT]: i18n.t('Common:PAYMENTS.CASH_RECEIVED'),
  }

  const CashChangeGivenMap: Partial<Record<PaymentType, string>> = {
    [PaymentType.PAYMENT]: i18n.t('Common:PAYMENTS.CHANGE_GIVEN'),
  }

  return {
    amountLabelRaw:
      (paymentTypeLabel && AmountLabelRawMap[paymentTypeLabel]) || '',
    toLabel: (paymentTypeLabel && ToLabelMap[paymentTypeLabel]) || '',
    recordedByLabel:
      (paymentTypeLabel && RecordedByLabelMap[paymentTypeLabel]) || '',
    methodLabel: (paymentTypeLabel && MethodLabelMap[paymentTypeLabel]) || '',
    dateLabel: (paymentTypeLabel && DateLabelMap[paymentTypeLabel]) || '',
    cashReceived: (paymentTypeLabel && CashReceivedMap[paymentTypeLabel]) || '',
    cashChangeGiven:
      (paymentTypeLabel && CashChangeGivenMap[paymentTypeLabel]) || '',
  }
}

export const getPaymentStatusLabelName: Partial<Record<PaymentLabel, string>> =
  {
    Applied: i18n.t('Common:STATUS_APPLIED'),
    Authorized: i18n.t('Common:AUTHORIZED'),
    Unapplied: i18n.t('Common:UNAPPLIED'),
    DebitAdjustment: i18n.t('Payments:PAYMENT_TYPES.ADJUSTMENT_DEBIT'),
    DebitImported: i18n.t('Payments:PAYMENT_TYPES.IMPORTED_DEBIT'),
    PartiallyApplied: i18n.t('Common:PARTIALLY_APPLIED'),
    Refund: i18n.t('Common:PAYMENTS.REFUND_ACTION'),
    Refunded: i18n.t('Common:PAYMENTS.REFUNDED'),
    Void: i18n.t('Common:VOID'),
    Voided: i18n.t('Common:PAYMENTS.VOIDED'),
    Reversed: i18n.t('Common:REVERSED'),
    ReverseCharge: i18n.t('Common:REVERSE_CHARGE'),
  }

export const getValidRefundPaymentForDeposit = (
  invoiceOrEstimate: InvoiceOrEstimate,
) =>
  invoiceOrEstimate.payments?.find(
    (payment) =>
      // casting here because GQL response is using REST response field. Needs to be fixed in future
      Boolean((payment as ExtendPayment)?.unappliedAmount) &&
      ((payment as unknown as GraphQlPayment)?.type?.name ===
        PaymentType.DEPOSIT ||
        payment.paymentType === PaymentType.DEPOSIT),
  ) as ExtendPayment

export const getDepositPaymentIds = (invoice: InvoiceOrEstimate) =>
  invoice.payments
    ?.filter((payment) => payment.paymentType === PaymentType.DEPOSIT)
    ?.map((payment) => payment.id)

export const extractInvoicesByType = (payment: ExtendPayment) =>
  R.reduce(
    (
      acc: {
        estimates: Invoice[]
        invoices: Invoice[]
      },
      item,
    ) => {
      if (item.invoiceType === InvoiceType.INVOICE.toUpperCase()) {
        acc.invoices.push(item)
      } else if (item.invoiceType === InvoiceType.ESTIMATE.toUpperCase()) {
        acc.estimates.push(item)
      }
      return acc
    },
    { invoices: [], estimates: [] },
    payment.invoices || [],
  )

export const getInvoicePaymentIds = (
  depositPaymentIds: string[],
  paymentsMap: Record<string, ExtendPayment>,
) =>
  depositPaymentIds
    .map(
      (depositPaymentId) =>
        (paymentsMap[depositPaymentId]?.invoices || [])
          .filter(
            (invoice) =>
              invoice.invoiceType === InvoiceType.INVOICE.toUpperCase(),
          )
          .map((invoice) => invoice.id) ?? [],
    )
    .flat()

export const isCredit = (amount: number, rowType: string) => {
  const multi = getRawPaymentTypeMultiplier[rowType as PaymentType]
  return !R.isNil(multi) ? amount * multi > 0 : false
}

export const labelStyles: Partial<Record<PaymentLabel, string>> = {
  Applied: 'success',
  Authorized: 'success',
  DebitAdjustment: 'success',
  DebitImported: 'success',
  Unapplied: 'warning',
  PartiallyApplied: 'warning',
  Void: 'disabled',
  Voided: 'disabled',
  Reversed: 'disabled',
  ReverseCharge: 'disabled',
}

const getSummAmount = (payment: GraphQlPayment) =>
  payment.links
    ? payment.links?.reduce(
        (total, link) =>
          Utils.round(total + (link.paidAmountNoFee ?? 0), 2) ?? 0,
        0,
      )
    : 0
const isPaymentType = (
  payment: GraphQlPayment | GraphQlGoPayment,
): payment is GraphQlPayment => payment.__typename === 'Payment'
const isPartiallyAppliedPayment = (
  payment: GraphQlPayment | GraphQlGoPayment,
) =>
  isPaymentType(payment) &&
  (payment.unappliedAmount > 0 ||
    (payment.type?.name === PaymentType.ADJUSTMENT &&
      getSummAmount(payment) < Math.abs(payment.amount)))

const isUnappliedPayment = (payment: GraphQlPayment | GraphQlGoPayment) =>
  isPaymentType(payment) &&
  (payment.unappliedAmount === payment.amount - payment.serviceFeeIncAmount ||
    ((payment.type?.name === PaymentType.ADJUSTMENT ||
      payment.type?.name === PaymentType.IMPORTED) &&
      payment.amount < 0 &&
      !payment.links))
const isPartiallyRefundedPayment = (
  payment: GraphQlPayment | GraphQlGoPayment,
) =>
  isPaymentType(payment) &&
  !R.isNil(payment.refundableAmount) &&
  payment.refundableAmount < payment.amount

export const hasUnappliedPayment = (
  payment: GraphQlPayment | GraphQlGoPayment,
) =>
  isUnappliedPayment(payment) ||
  isPartiallyAppliedPayment(payment) ||
  isPartiallyRefundedPayment(payment)

export const getPaymentWidgetStatusLabel = (
  credit: boolean,
  payment: GraphQlPayment | GraphQlGoPayment,
): PaymentLabel[] => {
  const labels = [] as PaymentLabel[]

  if (isPaymentType(payment)) {
    if (credit) {
      if (payment.reversed === true) {
        labels.push(PaymentLabel.Reversed)
      } else if (payment.undone) {
        labels.push(PaymentLabel.Voided)
      } else if (isPartiallyRefundedPayment(payment)) {
        labels.push(PaymentLabel.Refunded)
      } else if (isUnappliedPayment(payment)) {
        labels.push(PaymentLabel.Unapplied)
      } else if (isPartiallyAppliedPayment(payment)) {
        labels.push(PaymentLabel.PartiallyApplied)
      } else if (payment.unappliedAmount === 0) {
        labels.push(PaymentLabel.Applied)
      }
    } else if (payment.type?.name === PaymentType.ADJUSTMENT) {
      labels.push(PaymentLabel.DebitAdjustment)
    } else if (payment.type?.name === PaymentType.IMPORTED) {
      labels.push(PaymentLabel.DebitImported)
    } else if (payment.type?.name === PaymentType.UNDO) {
      labels.push(PaymentLabel.Void)
    } else if (payment.type?.name === PaymentType.REVERSE_CHARGE) {
      if (payment.reversed) {
        labels.push(PaymentLabel.ReverseCharge)
      } else {
        labels.push(PaymentLabel.Refund)
      }
    }
  }

  if (
    'transactionStateName' in payment &&
    payment.transactionStateName === PaymentLabel.Authorized
  ) {
    labels.push(PaymentLabel.Authorized)
  }

  return labels
}

export const getPaymentWidgetTypeLabel = (paymentType?: string) =>
  paymentType === PaymentType.IMPORTED
    ? PaymentTypesLabel[PaymentType.IMPORTED]
    : paymentType || ''

export const getPaymentMethodString = (
  payment: ExtendPayment | Payment | GraphQlPayment | Nil,
) => {
  if (isNil(payment)) {
    return ''
  }

  const description =
    'description' in payment ? payment.description || payment.paymentMethod : ''
  const paymentMethodName = 'method' in payment ? payment.method?.name : ''
  return LanguageUtils.getSentence(
    [
      description || paymentMethodName,
      'lastFour' in payment ? payment.lastFour : null,
    ],
    SentenceFormatter.REGULAR,
  )
}

export const getPaymentInvoiceIds = (
  payment: ExtendPayment | Payment | Nil,
): string[] => {
  if (!payment) {
    return []
  }

  if ('invoices' in payment && payment.invoices) {
    return payment.invoices.map(({ id }) => id)
  }

  return payment.invoiceIds
    ? payment.invoiceIds
    : payment.invoiceId
    ? [payment.invoiceId]
    : []
}

export const getPaymentType = (
  payment: GraphQlPayment | Payment | ExtendPayment,
) => {
  if ('paymentType' in payment && !isNil(payment.paymentType)) {
    return payment.paymentType
  }
  if ('type' in payment && !isNil(payment.type)) {
    if (typeof payment.type === 'string') {
      return payment.type
    }
    if (!isNil(payment.type?.name)) {
      return payment.type?.name
    }
  }
  return null
}

export const getPaymentFlags = (
  payment: ExtendPayment,
  PaymentTransactionState: NamedEntity[],
) => {
  const {
    paymentType,
    state: paymentState,
    transactionState,
    paymentMethod,
    amount = 0,
  } = payment ?? {}

  const ErrorType = Utils.findConstantIdByName(
    PAYMENT_TRANSACTION_STATE.ERROR,
    PaymentTransactionState,
  )
  const AuthorizedType = Utils.findConstantIdByName(
    PAYMENT_TRANSACTION_STATE.AUTHORIZED,
    PaymentTransactionState,
  )

  return {
    isRefund: paymentType === PaymentType.REVERSE_CHARGE,
    isUndo: paymentType === PaymentType.UNDO,
    isVoid: paymentState === VOIDED,
    isAuth:
      transactionState === AuthorizedType &&
      paymentMethod === RhapsodyGoPaymentMethod.CREDIT_CARD,
    isCreditAdjustment: checkIsCreditAdjustment(payment),
    isDebitAdjustment: checkIsDebitAdjustment(payment),
    isFailedPayment: transactionState === ErrorType,
    isCreditPayment: isCredit(amount || 0, paymentType || ''),
  }
}

export const composePatientNames = (names: string[]) => {
  if (names.length >= 2) {
    return `${names.slice(0, -1).join(', ')}${
      names.length > 2 ? ', and' : ' and'
    } ${names.at(-1)}`
  }
  return names[0]
}
