enum FeatureToggle {
  AAHA_VIDEO_MENU = 'AAHA_VIDEO_MENU',
  ACTIVE_RX = 'ACTIVE_RX',
  ADMIN_NOTES_ACTIONS = 'ADMIN_NOTES_ACTIONS',
  APPOINTMENT_CANCELLATION_REASON = 'APPOINTMENT_CANCELLATION_REASON',
  APPOINTMENT_DIALOG_ADDITIONAL_FIELDS = 'APPOINTMENT_DIALOG_ADDITIONAL_FIELDS',
  ALLOW_VET_ASSISTANT_ASSIGNMENT_TO_APPOINTMENTS = 'ALLOW_VET_ASSISTANT_ASSIGNMENT_TO_APPOINTMENTS',
  ATTACH_FILES_TO_LAB_ORDERS = 'ATTACH_FILES_TO_LAB_ORDERS',
  AUTO_ASSIGN_EVENT = 'AUTO_ASSIGN_EVENT',
  AUTO_CREATE_TASKS_FOR_RX_FLOW = 'AUTO_CREATE_TASKS_FOR_RX_FLOW',
  AUTO_CWAV_VCR_ROLES_ASSIGN = 'AUTO_CWAV_VCR_ROLES_ASSIGN',
  AUTO_POPULATE_DRUG_RX = 'AUTO_POPULATE_DRUG_RX',
  AUTO_REPLY_RHAPSODY_COMMUNICATION = 'AUTO_REPLY_RHAPSODY_COMMUNICATION',
  AUTOMATED_VACCINE_GENERATION = 'AUTOMATED_VACCINE_GENERATION',
  BANK_RECON_REPORT = 'BANK_RECON_REPORT',
  BOOP_DISABLEMENT = 'BOOP_DISABLEMENT',
  BUSINESS_ID_URL_PARAM = 'BUSINESS_ID_URL_PARAM',
  BUSINESS_PHONE_NUMBER_RELY_ON_LOCATION = 'BUSINESS_PHONE_NUMBER_RELY_ON_LOCATION',
  CAGE_CARD_PATIENT_ALERTS = 'CAGE_CARD_PATIENT_ALERTS',
  CANCEL_APPOINTMENTS_AND_WPLAN_FOR_DECEASED_PET = 'CANCEL_APPOINTMENTS_AND_WPLAN_FOR_DECEASED_PET',
  CANCELLATIONS_AND_NOSHOWS_DASHBOARD_V2 = 'CANCELLATIONS_AND_NOSHOWS_DASHBOARD_V2',
  CHARGE_SHEET = 'CHARGE_SHEET',
  CHEWY_ACCOUNT_LINKER = 'CHEWY_ACCOUNT_LINKER',
  CHEWY_RX_FINALIZATION = 'CHEWY_RX_FINALIZATION',
  CHEWY_VET_CARE_REPORTS = 'CHEWY_VET_CARE_REPORTS',
  CLIENT_INITIATED_REQUEST_DASHBOARD = 'CLIENT_INITIATED_REQUEST_DASHBOARD',
  CVC_ROLES = 'CVC_ROLES',
  DELETE_FINALIZED_LAB_TESTS = 'DELETE_FINALIZED_LAB_TESTS',
  DISCOUNT_GROUPS = 'DISCOUNT_GROUPS',
  DISCOUNT_REASON = 'DISCOUNT_REASON',
  DOCUMENT_FULL_SCREEN = 'DOCUMENT_FULL_SCREEN',
  EASTER_EGG = 'EASTER_EGG',
  EDIT_POSTED_CHARGES = 'EDIT_POSTED_CHARGES',
  EMPLOYEE_ID_AUTOGENERATION = 'EMPLOYEE_ID_AUTOGENERATION',
  EULA_V3 = 'EULA_V3',
  EXPOSE_FLAVOR = 'EXPOSE_FLAVOR',
  FINALIZE_WIDGET = 'FINALIZE_WIDGET',
  FOOD_CATALOG = 'FOOD_CATALOG',
  GROUP_CLIENT_SHARING = 'GROUP_CLIENT_SHARING',
  GROUP_DOCUMENTS_SHARING = 'GROUP_DOCUMENTS_SHARING',
  HANDLE_DECLINED_LAB_ORDER_RESULTS = 'HANDLE_DECLINED_LAB_ORDER_RESULTS',
  HIDE_APPOINTMENT_CONFIGURATION_IN_PRACTICE_SETTINGS_TAB = 'HIDE_APPOINTMENT_CONFIGURATION_IN_PRACTICE_SETTINGS_TAB',
  HIDE_COMMUNICATION_SETTINGS_FOR_OMNI_CHANNEL = 'HIDE_COMMUNICATION_SETTINGS_FOR_OMNI_CHANNEL',
  IMPORT_V3_PORTAL_INTEGRATION = 'IMPORT_V3_PORTAL_INTEGRATION',
  INJECTABLE_DEFAULT_WORKFLOW = 'INJECTABLE_DEFAULT_WORKFLOW',
  INPUT_FORMS_DOCUMENT = 'INPUT_FORMS_DOCUMENT',
  INVOICE_BASED_REFUNDS = 'INVOICE_BASED_REFUNDS',
  IPO_M0_CREDIT_ADJUSTMENT = 'IPO-M0_CREDIT_ADJUSTMENT',
  IPO_M0_ESTIMATES = 'IPO-M0_ESTIMATES',
  IPO_M0_REVAMP_CLIENT_BILLING_PAGE = 'IPO-M0_REVAMP_CLIENT_BILLING_PAGE',
  IPO_M0_VOID_AND_PAYMENT_REVERSAL = 'IPO-M0_VOID_AND_PAYMENT_REVERSAL',
  IPO_M0_NEW_INVOICE_FOOTER = 'IPO_M0_NEW_INVOICE_FOOTER',
  IPO_M2B_BRAINTREE_SIGNUP = 'IPO-M2B_BRAINTREE_SIGNUP',
  IPO_M1_CHECKOUT = 'IPO-M1_CHECKOUT',
  LINK_TO_UPDATE_WPLANS_PAYMENT_METHOD_FOR_CVC = 'LINK_TO_UPDATE_WPLANS_PAYMENT_METHOD_FOR_CVC',
  MASTER_PROBLEMS_WIDGET = 'MASTER_PROBLEMS_WIDGET',
  MEDICAL_HISTORY_DATE_FILTER = 'MEDICAL_HISTORY_DATE_FILTER',
  MULTI_BOTTLE_LEVEL_FUNCTIONALITY_TO_PRESCRIPTIONS = 'MULTI_BOTTLE_LEVEL_FUNCTIONALITY_TO_PRESCRIPTIONS',
  NPS_CLINIC_LEVEL = 'NPS_CLINIC_LEVEL',
  OBSOLETE_GLOBAL_INVENTORY_ITEM_VARIATION = 'OBSOLETE_GLOBAL_INVENTORY_ITEM_VARIATION',
  OPTIMIZE_INVENTORY_SHIPMENTS_LOADING = 'OPTIMIZE_INVENTORY_SHIPMENTS_LOADING',
  OMNICHANNEL_ANALYTICS_MENU = 'OMNICHANNEL_ANALYTICS_MENU',
  OTC_SALES_ON_PATIENT_TIMELINE = 'OTC_SALES_ON_PATIENT_TIMELINE',
  PATIENT_SHARING = 'PATIENT_SHARING',
  PRICE_MATCH = 'PRICE_MATCH',
  PROBLEMS_SOAP_WIDGET = 'PROBLEMS_SOAP_WIDGET',
  QUALTRICS_INTEGRATION = 'QUALTRICS_INTEGRATION',
  QUESTIONS_WIDGET = 'QUESTIONS_WIDGET',
  RHAP_APPT_TYPE_NAMING_UPDATES = 'RHAP_APPT_TYPE_NAMING_UPDATES',
  RC_INTEGRATION = 'RC_INTEGRATION',
  RC_PHASE_2_INTEGRATION = 'RC_PHASE_2_INTEGRATION',
  RC_RECONCILATION_ENABLED = 'RC_RECONCILATION_ENABLED',
  REACTIVE_RX = 'REACTIVE_RX',
  REMOTE_MEMBERS = 'REMOTE_MEMBERS',
  REPORTING_DYNAMIC_RHAPSODY_DOMAIN = 'REPORTING_DYNAMIC_RHAPSODY_DOMAIN',
  RHAPSODY_GO_STRIPE_INTEGRATION = 'RHAPSODY_GO_STRIPE_INTEGRATION',
  RHAPSODY_PAY_BRAINTREE_INTEGRATION = 'RHAPSODY_PAY_BRAINTREE_INTEGRATION',
  SEND_REMINDER_VIA_SMS_BOOP = 'SEND_REMINDER_VIA_SMS_BOOP',
  SEND_SMS_VIA_POLARIS = 'SEND_SMS_VIA_POLARIS',
  SMS_CONSENT_TOGGLE = 'SMS_CONSENT_TOGGLE',
  SOAP_CUSTOMIZATION = 'SOAP_CUSTOMIZATION',
  SOURCE_IN_SHIPMENT_TABLE = 'SOURCE_IN_SHIPMENT_TABLE',
  SPACE_MONITOR_MANAGEMENT = 'SPACE_MONITOR_MANAGEMENT',
  SSO_INTEGRATION = 'SSO_INTEGRATION',
  SSO_PAYMENT_TERMINALS = 'SSO_PAYMENT_TERMINALS',
  SUPPRESS_ADD_CLIENTS_AND_PATIENTS = 'SUPPRESS_ADD_CLIENTS_AND_PATIENTS',
  TASK_LIST_ORACLE_INTEGRATION = 'TASK_LIST_ORACLE_INTEGRATION',
  TASK_OPTIMIZATION = 'TASK_OPTIMIZATION',
  TEAMS_PHASE_2 = 'TEAMS_PHASE_2',
  TEAM_ASSIGNMENT = 'TEAM_ASSIGNMENT',
  TIMEZONE_LIST_REORDERING = 'TIMEZONE_LIST_REORDERING',
  UNLINK_INVOICES = 'UNLINK_INOICES',
  VACCINATION_DURATION_OF_IMMUNITY_BASED_ON_REMINDER_PROTOCOL = 'VACCINATION_DURATION_OF_IMMUNITY_BASED_ON_REMINDER_PROTOCOL',
  VETCOVE_SETUP = 'VETCOVE_SETUP',
  VITALS_WIDGET = 'VITALS_WIDGET',
  WELCOME_EMAIL_FOR_OMNICHANNEL = 'WELCOME_EMAIL_FOR_OMNICHANNEL',
  WELLNESSPLAN_SHARING = 'WELLNESSPLAN_SHARING',
  WP_AUTO_RENEWAL = 'WP_AUTO_RENEWAL',
  WP_IMPORT_PHASE_1 = 'WP_IMPORT_PHASE_1',
  WP_SURFACE_CLIENT_STATUS = 'WP_SURFACE_CLIENT_STATUS',
  ZOOM_INTEGRATION = 'ZOOM_INTEGRATION',
  PERSIST_EXAM_FINDINGS_FOR_SOAP = 'PERSIST_EXAM_FINDINGS_FOR_SOAP',
}

export default FeatureToggle
